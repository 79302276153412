/*
 * P&F DRE web application
 *
 * Display single database connection and a button to disconnect it.
 */

import * as React from 'react';
import { ApolloError } from '@apollo/client';
import Moment from 'moment';
import { WebsocketConnectionListQuery } from '../../generated/graphql';
import Button from '../Button';
import './styles.css';

export type TConnection = WebsocketConnectionListQuery['dashboardViewers'][0];

interface Props {
	connection: TConnection;
}

const WebsocketConnectionListEntry: React.FC<Props> = ({
	connection: c,
}) => {

	const [hostname, setHostname] = React.useState<string | null>();

	// Convert the IP address back into a hostname.  This does issue multiple
	// requests for the same IP, however the browser caches them so no need for us
	// to do any caching here.
	const ip = (c.sourceIP === '') ? null : c.sourceIP;
	React.useEffect(() => {
		(async () => {
			if (!ip) return;

			const ipc = ip.split('.');
			const revDNSq = [ipc[3], ipc[2], ipc[1], ipc[0], 'in-addr', 'arpa', ''].join('.');
			const url = new URL('https://dns.google/resolve');
			url.searchParams.append('name', revDNSq);
			url.searchParams.append('type', 'PTR');
			const res = await fetch(url);
			if (!res.ok) return;

			const answer = await res.json();
			let host: string = answer?.Answer?.[0]?.data;
			if (!host) return;

			if (host.substring(host.length - 1) === '.') {
				host = host.substring(0, host.length - 1);
			}
			setHostname(host ?? null);
		})();
	}, [
		ip,
		setHostname,
	]);

	let hostText;
	if (hostname) {
		hostText = hostname;
		if (ip) {
			hostText += ' (' + ip + ')';
		}
	} else {
		hostText = ip;
	}

	return (
		<li className="WebsocketConnectionListEntry">
			<i>🖥️</i>
			<div className="left">
				<h4>
					{c.application ?? (
						<i>No application name provided</i>
					)}
				</h4>
				<div className="subtitle">
					<div className="ip" title="Connection origin">{hostText}</div>
					<div className="cid" title="AWS Websocket ID">{c.idConnection ?? '-'}</div>
				</div>
			</div>
		</li>
	);
};

export default WebsocketConnectionListEntry;
