/*
 * P&F Demand Response Engine
 *
 * Interface around LiveData to delay incoming messages such that they arrive
 * at a regular rate, instead of coming in bursts every few seconds.
 */

import { TMetrics } from './live';

export type TFnAddReading = (deviceCode: string, metrics: TMetrics) => void;

async function sleep(msDelay: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, msDelay);
	});
}

class SmoothMessages {
	protected tLastReading: number;
	protected cbAddReading: TFnAddReading;

	constructor(cbAddReading: TFnAddReading) {
		this.tLastReading = Date.now();
		this.cbAddReading = cbAddReading;
	}

	async liveDataCallback(readings: Record<string, TMetrics[]>) {
		// Figure out how long since the last message.
		const tNow = Date.now();
		const tSince = 5000;//tNow - this.tLastReading;
		this.tLastReading = tNow;

		const readingsList = Object.entries(readings);

		let numItems = 0;
		for (const [deviceCode, readings] of readingsList) {
			numItems += readings.length;
		}

		// Apportion delay to each of the new items.
		const tDelay = tSince / numItems;

		let first = true;
		for (const [deviceCode, readings] of readingsList) {
			for (const metrics of readings) {
				if (!first) {
					// Send the first item immediately, delay after all items except the
					// last.
					await sleep(tDelay);
				} else {
					first = false;
				}
				this.cbAddReading(deviceCode, metrics);
			}
		}
	}

	getCallback() {
		return this.liveDataCallback.bind(this);
	}
};

export default SmoothMessages;
