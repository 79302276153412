/*
 * P&F DRE web application
 *
 * Styled button.
 */

import * as React from 'react';
import './styles.css';

type ButtonType =
	'add'
	| 'refresh'
	| 'accept'
	| 'search'
	| 'startCharge'
	| 'stopCharge'
	| 'startDischarge'
	| 'terminate'
	| 'trigger'
	| 'undo'
	;

interface Props {
	isActive?: boolean;
	className?: string;
	type?: ButtonType;
	title?: string;
	onClick?: () => void;
	enabled?: boolean;
	size?: 'small' | 'link';
	children?: React.ReactNode;
}

type TTimer = ReturnType<typeof setTimeout>;

// How long to show the 'finished' icon for, in milliseconds.
const FINISHED_DELAY_MS = 1000;

const Button: React.FC<Props> = (props) => {
	const [wasActive, setWasActive] = React.useState<boolean>(false);
	const [showFinished, setShowFinished] = React.useState<boolean>(false);
	const [lastTimer, setLastTimer] = React.useState<TTimer | undefined>();

	const { isActive } = props;

	React.useEffect(() => {
		if (!wasActive && isActive) {
			setWasActive(true);
			setShowFinished(false);
			if (lastTimer) {
				clearTimeout(lastTimer);
			}
		}

		if (wasActive && !isActive) {
			setShowFinished(true);
			setWasActive(false);
			if (lastTimer) {
				clearTimeout(lastTimer);
			}
			setLastTimer(
				setTimeout(() => {
					setShowFinished(false);
				}, FINISHED_DELAY_MS)
			);
		}
	}, [
		wasActive,
		isActive,
		lastTimer,
		setShowFinished,
		setWasActive,
	]);

	const content = props.children ?? (
		{
			'accept': 'OK',
			'add': 'Add',
			'refresh': 'Refresh',
			'search': 'Search',
			'startCharge': 'Charge',
			'startDischarge': 'Discharge',
			'stopCharge': 'Suspend',
			'terminate': 'Terminate',
			'trigger': 'Trigger',
			'undo': 'Undo',
			'*': '?',
		}[props.type ?? '*'] || '?'
	);

	const icon = (
		// Use a zero-width space for links, to get the same height as an icon
		// for alignment purposes.
		props.size === 'link' ? '\u200B' : null
	) || ({
		'accept': '✅',
		'add': '➕',
		'refresh': '🔄',
		'search': '🔍',
		'startCharge': '⚡',
		'startDischarge': '🔌',
		'stopCharge': '🚫',
		'terminate': '🚫',
		'trigger': '▶️',
		'undo': '↩️',
		'*': '\u200B', // zero-width space, to get height to match an icon
	}[props.type ?? '*'] ?? null);

	const className = (
		'Button '
		+ (props.type ?? '')
		+ (props.className ? ' ' + props.className : '')
		+ (props.enabled === false ? ' disabled' : '')
		+ (props.size ? ' ' + props.size : '')
	);

	const classNameActive = 'active ' + (props.isActive ? '' : 'fade');
	const classNameFinished = showFinished ? '' : 'fade';

	const styleDefault = { opacity: (props.isActive || showFinished) ? 0 : 1 };
	const styleActive = { opacity: props.isActive ? 1 : 0 };
	const styleFinished = { opacity: showFinished ? 1 : 0 };

	return (
		<button
			className={className}
			onClick={props.onClick}
			title={props.title ?? ''}
			disabled={props.enabled === false}
		>
			<span className="icon">
				<span className="image" style={styleDefault}>{icon}</span>
				{props.type && (
					<>
						<span className={classNameActive} style={styleActive}>🚀</span>
						<span className={classNameFinished} style={styleFinished}>✅</span>
					</>
				)}
			</span>
			<span className="text">{content}</span>
		</button>
	);
};

export default Button;
