/*
 * P&F DRE web application
 *
 * Form that can submit to an API call.
 */

import * as React from 'react';

import { FormContext, TFormContext } from './index';
import InputTextExpanding from '../InputTextExpanding';

import './styles.css';

export type TFnSave = (values: Record<string, string>) => void;

const FormInputTextExpanding: React.FC<any> = (props: any) => {

	const childProps = React.useMemo(() => {
		let p = { ...props };
		delete p.field;
		return p;
	}, [
		props,
	]);

	const { field } = props;

	return (
		<FormContext.Consumer>
			{(cxtForm: TFormContext) => (
				<>
					<InputTextExpanding
						{...childProps}
						value={cxtForm.activeValues[field] ?? ''}
						onChange={c => cxtForm.setValue(field, c.target.value)}
					/>
				</>
			)}
		</FormContext.Consumer>
	);
};

export default FormInputTextExpanding;
