/*
 * P&F Demand Response Engine
 *
 * Quick Open Bar displayed in the top-right corner of the screen, to allow
 * quickly opening devices by code.
 *
 * Type `debug/error` into the quick open bar to display a sample error
 * message for test and styling purposes.
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';

import InputTextExpanding from '../InputTextExpanding';
import {
	linkDeviceView,
	linkSearchResults,
} from '../../links';
import {
	useDeviceSearchLazyQuery,
} from '../../generated/graphql';
import './styles.css';

// Look through the search results to find a device that matches the code.
// This is because we use the same query as the device search, which may return
// unrelated devices (e.g. searching for "12.1" will return "312.1"), which we
// do so that the quick open box shares the same cache as the search.
function findDevice(code: string, gateways: any) {
	for (const g of gateways) {
		if (g.code === code) {
			return g;
		}
		for (const m of g.meters ?? []) {
			if (m.code === code) {
				return m;
			}
		}
	}

	return null;
}

const QuickOpenBar = () => {
	const navigate = useNavigate();

	// Controlled text field, updated as the user types.
	const [criteria, setCriteria] = React.useState('');

	// Same as text field, but only updated when user presses enter.
	const [activeCriteria, setActiveCriteria] = React.useState<string | null>(null);

	const [errorMsg, setErrorMsg] = React.useState<string[]>([]);

	//const [filter, setFilter] = React.useState({});
	const [deviceSearch, { data, error, loading }] = useDeviceSearchLazyQuery();

	const onSubmitQuickOpen = React.useCallback((
		event: React.FormEvent<HTMLFormElement | HTMLButtonElement>
	): void => {
		event.preventDefault();

		setActiveCriteria(criteria);
		if (!criteria) return;

		// Try to guess what has been entered.
		if (criteria.includes('.')) {
			// Look up the device code and see if it matches a device ID.
			deviceSearch({
				variables: {
					filter: {
						code: criteria,
					},
				},
			});
		}
	}, [
		criteria,
		deviceSearch,
		setActiveCriteria,
	]);

	// Once the query returns data, try to find the device the user searched for
	// in the query response (as it returns more than one device, and may be
	// merged in with the Apollo cache from earlier searches).
	const targetURL = React.useMemo(() => {
		// Don't do anything while the query is still running.
		if (loading) return null;

		// If no term has been search for, we don't want to load any URL.
		if (!activeCriteria) return null;

		// If the query came back with an error, show that but don't load any URL.
		if (error) {
			setErrorMsg(error.graphQLErrors.map(s => s.message));
			return null;
		}

		if (activeCriteria === 'debug/error') {
			setErrorMsg([
				'This is a test error message',
				'Plus a second test error message',
			]);
			setActiveCriteria(null);
			return;
		}

		const foundDevice = findDevice(activeCriteria, data?.deviceList ?? []);
		if (foundDevice) {
			// Found a device, go straight to it.
			return linkDeviceView(foundDevice.id);

		} else {
			// No devices match, go to the search page.  We put the criteria into the
			// 'name' field, as we already know there are no results for 'code'.
			return linkSearchResults({ name: activeCriteria });
		}
	}, [
		activeCriteria,
		data,
		error,
		loading,
		setActiveCriteria,
	]);

	React.useEffect(() => {
		if (targetURL) {
			// Clear any error message from a previous search.
			setErrorMsg([]);

			// Clear any active search, so the browser back button works.
			setActiveCriteria(null);

			// Jump to the new page.
			navigate(targetURL);
		}
	}, [
		navigate,
		targetURL,
	]);

	return (
		<div className="QuickOpenBar" onMouseLeave={() => setErrorMsg([])}
		>
			<form onSubmit={onSubmitQuickOpen}>
				<InputTextExpanding
					placeholder="Quick open"
					size={8}
					value={criteria}
					onChange={ev => setCriteria(ev.target.value)}
				/>
			</form>
			<div className={'error ' + (errorMsg.length ? 'show' : 'hide')}>
				<ul>
					{errorMsg.map(msg => (
						<li key={msg}>{msg}</li>
					))}
				</ul>
			</div>
		</div>
	);
};


export default QuickOpenBar;
