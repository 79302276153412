/*
 * P&F DRE web application
 *
 * Page for searching the list of devices.
 */

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	linkDeviceView,
	linkSearchResults,
} from '../../../links';
import DeviceSearchFields from '../../../components/DeviceSearchFields';
import DeviceSearchResults from '../../../components/DeviceSearchResults';
import { Page, PageHeading, PageContent } from '../../../components/Page';

import SearchInstructions from './SearchInstructions';

export interface DeviceSearchCriteria {
	scID?: string;
	scCode?: string;
	scName?: string;
};

// Function to construct a link to each device.
function fnGenerateLink(id: number, code: string) {
	return linkDeviceView(id);
}

const PageDeviceSearch: React.FC = () => {
	const navigate = useNavigate();

	// Extract the ?query=params from the end of the URL.
	const { search } = useLocation();
	const urlQuery = React.useMemo(() => new URLSearchParams(search), [search]);

	const onSearch = React.useCallback((newCriteria: Record<string, string>): void => {
		// We could use the state here to store the search criteria and results,
		// but by putting it into the URL and reading it back from the URL, it
		// becomes possible to both bookmark search results as well as use the
		// back button to move back to previous search results.
		const url = linkSearchResults(newCriteria);
		navigate(url);
	}, [
		navigate,
	]);

	const criteria = React.useMemo((): DeviceSearchCriteria => ({
		scID: urlQuery.get('id') || undefined,
		scCode: urlQuery.get('code') || undefined,
		scName: urlQuery.get('name') || undefined,
	}), [urlQuery]);

	const emptyCriteria = (
		!criteria.scID
		&& !criteria.scCode
		&& !criteria.scName
	);

	return (
		<Page classSuffix="DeviceSearch">
			<PageHeading title="Device Search">
				<DeviceSearchFields
					onSearch={onSearch}
					{...criteria}
				/>
			</PageHeading>
			<PageContent>
				{emptyCriteria ? (
					<SearchInstructions />
				) : (
					<DeviceSearchResults
						fnGenerateLink={fnGenerateLink}
						{...criteria}
					/>
				)}
			</PageContent>
		</Page>
	);
}

export default PageDeviceSearch;
