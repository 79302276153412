/*
 * P&F DRE web application
 *
 * Summary of debug pages.
 */

import React from 'react';
import { Link } from 'react-router-dom';

import { Page, PageHeading, PageContent } from '../../../components/Page';

import './styles.css';

const PageDebugSummary: React.FC = () => (
	<Page classSuffix="Debug">
		<PageHeading title="Debug" />
		<PageContent>
			<ul className="links">
				<li><Link to="ui"><i>📱</i>UI</Link></li>
				<li><Link to="errors"><i>🚨️</i>Errors</Link></li>
			</ul>
		</PageContent>
	</Page>
);

export default PageDebugSummary;
