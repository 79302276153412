/*
 * P&F DRE web application
 *
 * Heading for UI panel.
 */

import * as React from 'react';

interface TProps {
	children: React.ReactNode;
};

const PanelHeading: React.FC<TProps> = ({ children }) => (
	<div className="title">
		{children}
	</div>
);

export default PanelHeading;
