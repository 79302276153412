/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	parameters: any;
	setParameters: React.SetStateAction<any>;
}

const JobParametersSumMetricsDaily: React.FC<Props> = ({ parameters, setParameters }) => {
	return (
		<div className="parameters">
			<table className="fields">
				<tr>
					<td className="name">
						<label htmlFor="startDate">Start day:</label>
					</td>
					<td className="value">
						<input
							name="dayStart"
							type="date"
							onChange={ev => setParameters({
								...parameters,
								dayStart: ev.target.value,
							})}
						/>
						<span>
							This is the first day to process.
						</span>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="endDate">End day:</label>
					</td>
					<td className="value">
						<input
							name="dayEnd"
							type="date"
							onChange={ev => setParameters({
								...parameters,
								dayEnd: ev.target.value,
							})}
						/>
						<span>
							This is the day at which to stop processing.  This day is not
							processed, only up until this day.
						</span>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="deviceCodes">Device codes:</label>
					</td>
					<td className="value">
						<input
							name="deviceCodes"
							type="text"
							onChange={ev => setParameters({
								...parameters,
								deviceCodes: (
									(ev.target.value && ev.target.value.length > 0)
										? ev.target.value.split(',')
										: undefined
								),
							})}
						/>
						<span>
							Optional value.  A comma-separated list of device codes to
							update, e.g. <samp>78.08,78.09</samp>.  If omitted, data from all
							devices is processed.
						</span>
					</td>
				</tr>
			</table>
		</div>
	);
};

export default JobParametersSumMetricsDaily;
