/*
 * P&F DRE web application
 *
 * Routing for top-level pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from './error/invalid-url';
import { addProtectedRoute } from './error/addProtectedRoute';

import PageAssets from './assets';
import PageDashboards from './dashboards';
import PageDatabase from './db';
import PageDebug from './debug';
import PageDevices from './devices';
import PageHome from './home';
import PageSystem from './system';

const PageRoot: React.FC = () => (
	<Routes>
		{addProtectedRoute('/assets/*', PageAssets)}
		{addProtectedRoute('/dashboards/*', PageDashboards)}
		{addProtectedRoute('/db/*', PageDatabase)}
		{addProtectedRoute('/debug/*', PageDebug)}
		{addProtectedRoute('/devices/*', PageDevices)}
		{addProtectedRoute('/system/*', PageSystem)}
		{addProtectedRoute('/', PageHome)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageRoot;
