/*
 * P&F DRE web application
 *
 * Extract detail from an Apollo GraphQL API error, and display in a standard
 * error message box.
 */

import React from 'react';
import { ApolloError } from '@apollo/client';
import ErrorMessage from './ErrorMessage';
import PrettyException from './PrettyException';

import './styles.css';

interface Props {
	apolloError: ApolloError;
};

const APIError: React.FC<Props> = ({ apolloError }) => {
	return (
		<ErrorMessage title="API Error">
			<PrettyException exception={apolloError} />
		</ErrorMessage>
	);
}

export default APIError;
