/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	parameters: any;
	setParameters: React.SetStateAction<any>;
}

const JobParametersUpdateIntervalMetrics: React.FC<Props> = ({ parameters, setParameters }) => {
	return (
		<div className="parameters">
			<table className="fields">
				<tr>
					<td className="name">
						<label htmlFor="startTime">Start date/time:</label>
					</td>
					<td className="value">
						<input
							name="startTime"
							type="datetime-local"
							step="1"
							onChange={ev => setParameters({
								...parameters,
								startTime: ev.target.value,
							})}
						/>
						<span>
							This is the first timestamp to read.  This timestamp won't be
							updated though, as no previous reading will be available.  It
							will be the next timestamp available after this moment that will
							be the first to be updated.
						</span>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="endTime">End date/time:</label>
					</td>
					<td className="value">
						<input
							name="endTime"
							type="datetime-local"
							step="1"
							onChange={ev => setParameters({
								...parameters,
								endTime: ev.target.value,
							})}
						/>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="deviceCodes">Device codes:</label>
					</td>
					<td className="value">
						<input
							name="deviceCodes"
							type="text"
							onChange={ev => setParameters({
								...parameters,
								deviceCodes: (
									(ev.target.value && ev.target.value.length > 0)
										? ev.target.value.split(',')
										: undefined
								),
							})}
						/>
						<span>
							Optional value.  A comma-separated list of device codes to
							update, e.g. <samp>78.08,78.09</samp>.  If omitted, data from all
							devices is processed.
						</span>
					</td>
				</tr>
			</table>
		</div>
	);
};

export default JobParametersUpdateIntervalMetrics;
