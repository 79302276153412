/*
 * P&F DRE web application
 *
 * Error page, for exceptions.  Shows exception message and backtrace.  Due to
 * React limitations, the backtrace doesn't use the source maps.
 */

import React from 'react';

import PageError from './index';

interface TProps {
	heading?: string;
	title: string;
	children: React.ReactNode; // should be wrapped in <p></p>
	error: Error;
}

const PageErrorException: React.FC<TProps> = (props) => (
	<PageError heading={props.heading} title={props.title}>
		{props.children}
		<p>
			<b>{props.error.message}</b>
		</p>
		<pre>
			{props.error.stack}
		</pre>
	</PageError>
);

export default PageErrorException;
