/*
 * P&F DRE web application
 *
 * Standard page styling, header.
 */

import * as React from 'react';

import './styles.css';

interface TProps1 {
	actions?: React.ReactNode;
	children?: React.ReactNode;
	title: string;
}
interface TProps2 {
	actions?: React.ReactNode;
	children?: React.ReactNode;
	title: React.ReactNode;
	docTitle: string;
}
type TProps = TProps1 | TProps2;

const PAGE_TITLE_PREFIX = 'P&F EMS';
const PAGE_TITLE_DIVIDER = ' / ';

const PageHeading: React.FC<TProps> = (props) => {
	const docTitle = (props as TProps2).docTitle || props.title;
	React.useEffect(() => {
		document.title = PAGE_TITLE_PREFIX + (docTitle ? PAGE_TITLE_DIVIDER + docTitle : '');
	}, [
		docTitle,
	]);

	return (
		<>
			<h2 className="PageHeader">
				<span className="title">
					{props.title}
				</span>
				{props.actions && (
					<span className="actions">
						{props.actions}
					</span>
				)}
			</h2>
			{props.children ?? null}
		</>
	);
}

export default PageHeading;
