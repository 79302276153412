/*
 * P&F DRE web application
 *
 * Main application page.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import './App.css';

import InvalidURL from './pages/error/invalid-url';
import { addProtectedRoute } from './pages/error/addProtectedRoute';

import PageRoot from './pages';
import QuickOpenBar from './components/QuickOpenBar';
import Sidebar from './components/Sidebar';

function App() {
	return (
		<div className="App">
			<QuickOpenBar />
			<Sidebar />
			<div className="AppContent">
				<Routes>
					{addProtectedRoute('/*', PageRoot)}
					<Route path="*" element={<InvalidURL />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
