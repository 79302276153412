/*
 * P&F DRE web application
 *
 * <Panel/> with a <Form/> inside.  Combined so that the save/cancel buttons
 * can go at the top alongside the heading.
 */

import * as React from 'react';

import Button from '../Button';
import Form, { TFnSave, TFnFormSave, TFormData } from '../Form';
import { Panel, PanelActions } from '../Panel';
import Popup from '../Popup';
import { PrettyException } from '../ErrorMessage';
import Spinner from '../Spinner';

interface TProps {
	values: TFormData;
	fnSave: TFnSave;
	children?: React.ReactNode;
};

const FormPanel: React.FC<TProps> = ({ values, fnSave, children }) => {
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [modified, setModified] = React.useState(false);
	const [saveCallback, setSaveCallback] = React.useState<TFnFormSave | null>(null);
	const [exception, setException] = React.useState<Error | null>(null);

	const onSaveClick = React.useCallback(() => {
		if (saveCallback) saveCallback(false); // false = don't abandon changes
	}, [
		saveCallback,
	]);

	const onCancelClick = React.useCallback(() => {
		if (saveCallback) saveCallback(true); // true = abandon changes
		setException(null);
	}, [
		saveCallback,
		setException,
	]);

	// Insert a wrapper around the upstream save function, so we can intercept
	// the exception and set an error message above our 'save' link.
	const saveInterposer = React.useCallback<TFnSave>(async (newValues) => {
		try {
			setIsSaving(true);
			setException(null);
			await fnSave(newValues);

			// This will happen in a moment when the data is refreshed, but if we do
			// it now it will stop the save animation flicking back to 'save/cancel'
			// until the new data arrives.
			setModified(false);

		} catch (eu: any) {
			const e = eu as Error;
			setException(e);
			throw e;
		} finally {
			setIsSaving(false);
		}
	}, [
		fnSave,
		setIsSaving,
	]);

	const elSaveCancel = React.useMemo(() => {
		if (!modified) return null;
		return (
			<span className="saveCancel">
				{(isSaving && (
					<div className="saving">
						<span className="text">
							Saving
						</span>
						<span className="icon">
							<Spinner />
						</span>
					</div>
				)) || (
						<>
							<Popup visible={exception !== null} type="error">
								<b>Error while saving:</b>
								<PrettyException exception={exception} showMore={false} />
							</Popup>
							<Button type="accept" size="link" onClick={onSaveClick}>Save</Button>
							{' | '}
							<Button size="link" onClick={onCancelClick}>Cancel</Button>
						</>
					)}
			</span>
		);
	}, [
		exception,
		isSaving,
		modified,
		onCancelClick,
		onSaveClick,
	]);

	return (
		<Panel>
			<PanelActions>
				{elSaveCancel}
			</PanelActions>
			<Form
				values={values}
				fnSave={saveInterposer}
				fnSetModified={setModified}
				fnSetFormSave={setSaveCallback}
			>
				{children}
			</Form>
		</Panel>
	);
}

export default FormPanel;
