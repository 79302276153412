/*
 * P&F DRE web application
 *
 * Request a device search from the API and render the results.
 */

import * as React from 'react';
import {
	useDeviceSearchQuery,
	DeviceSearchQueryVariables,
	GtGateway,
} from '../../generated/graphql';

import DeviceList, { GatewayInfo } from '../DeviceList/DeviceList';
import Loading from '../Loading';
import { APIError } from '../ErrorMessage';
import { DeviceSearchCriteria } from '../DeviceSearchFields';
import { TDeviceInfo } from '../DeviceView';
import { TMeterInfo, TGatewayInfo } from '../DeviceView/DeviceView';

interface TProps extends DeviceSearchCriteria {
	fnGenerateLink: (id: number, code: string) => string;
};

// filter() callback for both gateways and meters.
const matchDevice = (d: any, args: any) => (
	(args.id === undefined || d.id === args.id)
	&& ((!args.code || args.code.length === 0) || d.code.toLowerCase().includes(args.code))
	&& ((!args.name || args.name.length === 0) || d.name.toLowerCase().includes(args.name))
);

const DeviceSearchResults: React.FC<TProps> = (props) => {
	const { scID, scCode, scName } = props;

	type TFilter = DeviceSearchQueryVariables['filter'];
	const filter = React.useMemo<TFilter>(() => {
		let vars: TFilter = {
			// id is handled below.
			code: scCode?.toLowerCase(),
			name: scName?.toLowerCase(),
		};
		if (scID?.length! > 0) {
			vars.id = parseInt(scID!, 10);
		}

		return vars;
	}, [
		scID,
		scCode,
		scName,
	]);

	// This query grabs the devices we want, but it merges the result into the
	// Apollo cache.  This means the query's return value also includes devices
	// from previous searches as well, so we need to filter these out afterwards.
	const { data, error, loading } = useDeviceSearchQuery({ variables: { filter } });

	function getMeters(d: any): TMeterInfo[] {
		if (d.__typename === 'GTGateway') {
			const g = d as TGatewayInfo;
			return g.meters ?? [];
		}
		return [];
	}

	const devices: GatewayInfo[] = React.useMemo(() => (
		(data?.deviceList.map(d => ({
			...d,
			meters: (getMeters(d).map(m => ({
				...m,
			})) ?? [])
				// We also have to filter the meter list, because thanks to the Apollo
				// cache, we will have entries from previous searches that we need to
				// ignore if they don't match the current search criteria.
				.filter(m => matchDevice(m, filter))
		})) ?? [])
			// As above we also have to filter the gateway list too.
			.filter(g => (
				matchDevice(g, filter)
				|| g.meters?.some((m: any) => (
					matchDevice(m, filter)
				))
			))
	), [
		data,
		filter,
	]);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <APIError apolloError={error} />;
	}

	return (
		<div className="DeviceSearchResults">
			<DeviceList
				gateways={devices}
				fnGenerateLink={props.fnGenerateLink}
			/>
		</div>
	);
};

export default DeviceSearchResults;
