/*
 * P&F DRE web application
 *
 * Error message box.  Expands to the size of the parent element.
 */

import React from 'react';

import './styles.css';

interface Props {
	title?: string | null;
	children: React.ReactNode;
};

const ErrorMessage: React.FC<Props> = (props) => (
	<div className="ErrorMessage">
		<div className="box">
			<div className="icon">⚠️</div>
			<div className="content">
				<h3>{props.title ?? "Error"}</h3>
				<div className="message">
					{props.children}
				</div>
			</div>
		</div>
	</div>
);

export default ErrorMessage;
