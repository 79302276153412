/*
 * P&F DRE web application
 *
 * Reusable error boundary implementation.
 */

import React from 'react';

export interface TProps {
	renderError: (error: Error) => React.ReactNode;
	children: React.ReactNode;
}

export interface TState {
	error?: Error;
}

class ErrorBoundary extends React.Component<TProps, TState> {
	public state: TState;

	constructor(props: TProps) {
		super(props);
		this.state = {
			error: undefined,
		};
	}

	static getDerivedStateFromError(error: Error): TState {
		// Update state so the next render will show the fallback UI.
		return {
			error: error,
		};
	}

	/*
	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, errorInfo);
		console.log('errorInfo', errorInfo);
	}
	*/

	render() {
		if (this.state.error) {
			// You can render any custom fallback UI
			return this.props.renderError(this.state.error);
		}
		return this.props.children;
	}
};

export default ErrorBoundary;
