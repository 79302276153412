/*
 * P&F DRE web application
 *
 * Routing for system pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageSystemCollector from './collector';

const PageSystem: React.FC = () => (
	<Routes>
		{addProtectedRoute('/collector', PageSystemCollector)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageSystem;
