/*
 * P&F Demand Response Engine
 *
 * Input text box that automatically expands as text is typed.
 *
 * All props are applied to the child <input/> element.
 */

import React from 'react';

import './styles.css';

type TProps = React.InputHTMLAttributes<HTMLInputElement>;

const InputTextExpanding = (props: TProps) => {
	return (
		<span className="ExpandingText" data-value={props.value}>
			<input
				type="text"
				size={2}
				{...props}
			/>
		</span>
	);
};


export default InputTextExpanding;
