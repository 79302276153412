/*
 * P&F DRE web application
 *
 * Routing for database-related pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../../error/invalid-url';
import { addProtectedRoute } from '../../error/addProtectedRoute';

import PageDatabaseJobsNew from './new';
import PageDatabaseJobsList from './list';

const PageDebug: React.FC = () => (
	<Routes>
		{addProtectedRoute('/new/', PageDatabaseJobsNew)}
		{addProtectedRoute('/', PageDatabaseJobsList)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageDebug;
