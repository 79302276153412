export function linkDeviceView(id: number) {
	return '/devices/view/' + id.toString();
}

export interface TSearchCriteria {
	id?: string;
	code?: string;
	name?: string;
};

export function linkSearchResults(sc: TSearchCriteria) {
	const searchParams = sc as Record<string, string>;
	const queryString = new URLSearchParams(searchParams);
	return '/devices/search?' + queryString.toString();
}
