/*
 * P&F DRE web application
 *
 * Page for summarising solar panel (PV) infrastructure.
 */

import React from 'react';

import { Page, PageHeading, PageContent } from '../../../../components/Page';

import './styles.css';

const PagePVSummary: React.FC = () => {
	return (
		<Page classSuffix="PVSummary">
			<PageHeading title="PV / Summary" />
			<PageContent>
				<div className="overview">
					Equipment operational: 100%<br />
					Failures: 0
				</div>
			</PageContent>
		</Page>
	);
}

export default PagePVSummary;
