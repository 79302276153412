/*
 * P&F DRE web application
 *
 * Render information about a single device, supplied by the parent.
 */

import * as React from 'react';

import {
	GtGateway,
	GtMeter,
} from '../../generated/graphql';
import { linkDeviceView } from '../../links';
import DeviceList from '../DeviceList/DeviceList';
import { ErrorMessage } from '../ErrorMessage';
import Information from '../Information';
import DeviceInfoBox from './DeviceInfoBox';
import PanelGrid from '../PanelGrid';
import {
	Panel,
	PanelContent,
	PanelHeading,
} from '../Panel';
import { FormPanel, FormInputTextExpanding, TFormData } from '../Form';

export type TGatewayInfo = GtGateway;
export type TMeterInfo = GtMeter;
export type TDeviceInfo = TGatewayInfo | TMeterInfo;

// Function to construct a link to each device.
function fnGenerateLink(id: number, _code: string) {
	return linkDeviceView(id);
}

interface Props {
	deviceInfo: TDeviceInfo;
	parentGateway: TDeviceInfo | null;
	fnUpdateDeviceInfo: (newData: any) => Promise<void>;
};

const DeviceView: React.FC<Props> = ({ deviceInfo, parentGateway, fnUpdateDeviceInfo }) => {

	const gatewayInfo: TGatewayInfo | undefined = React.useMemo(() => {
		if (deviceInfo.__typename === 'GTGateway') {
			return deviceInfo as TGatewayInfo;
		}
		return undefined;
	}, [
		deviceInfo,
	]);

	const meterInfo: TMeterInfo | undefined = React.useMemo(() => {
		if (deviceInfo.__typename === 'GTMeter') {
			return deviceInfo as TMeterInfo;
		}
		return undefined;
	}, [
		deviceInfo,
	]);

	const formData = deviceInfo as unknown as TFormData;

	return (
		<div className="DeviceView">
			<div className="main">
				{deviceInfo.lastError && (
					<ErrorMessage title="This device is currently reporting an error:">
						<p>{deviceInfo.lastError.message}</p>
					</ErrorMessage>
				)}

				<PanelGrid>
					<FormPanel values={formData} fnSave={fnUpdateDeviceInfo}>
						<PanelHeading>Device</PanelHeading>
						<PanelContent>
							<h3>
								Identification code
								<Information>
									The P&F-assigned unique code to identify this device.
								</Information>
							</h3>
							<FormInputTextExpanding field="code" />

							<h3>
								Name
								<Information>
									A short memorable title summarising what this device is for.
								</Information>
							</h3>
							<FormInputTextExpanding field="name" />

							<h3>
								Description
								<Information>
									An optional longer explanation about the device's purpose.
								</Information>
							</h3>
							<FormInputTextExpanding field="description" placeholder="None" />
						</PanelContent>
					</FormPanel>

					{gatewayInfo && (
						<FormPanel values={formData} fnSave={fnUpdateDeviceInfo}>
							<PanelHeading>Network</PanelHeading>
							<PanelContent>
								<h3>
									IP address
									<Information>
										The IPv4 address used to connect to this device.
									</Information>
								</h3>
								<FormInputTextExpanding field="ipv4Address" placeholder="Unknown" />
								<p>
									<small>
										Open in <a href="#todo" className="external">BlueCat</a> - todo
									</small>
								</p>

								<h3>
									MAC address
									<Information>
										The MAC address of the Ethernet socket used to connect
										the device to the UQ network.
									</Information>
								</h3>
								<FormInputTextExpanding field="macAddress" placeholder="Unknown" />

								<h3>
									Data outlet
									<Information>
										The label on the wall socket where this device's Ethernet
										cable is plugged in to.
									</Information>
								</h3>
								<FormInputTextExpanding field="dataOutlet" placeholder="Unknown" />

								<h3>Connected to switch</h3>
								<p>
									{gatewayInfo.switchName || (<i>Unknown</i>)}
									{gatewayInfo.switchPort && (
										<>
											on port
											{gatewayInfo.switchPort}
										</>
									)}
								</p>
							</PanelContent>
						</FormPanel>
					)}

					{deviceInfo.location && (
						<Panel>
							<PanelHeading>Location</PanelHeading>
							<PanelContent>
								<h3>Name</h3>
								<p>
									{deviceInfo.location.name}{' '}
									{deviceInfo.location && deviceInfo.location.coordinates && (
										<>
											(<a target="_blank" className="external" href={`https://www.google.com.au/maps/place/${deviceInfo.location.coordinates.latitude},${deviceInfo.location.coordinates.longitude}`}>Google Maps</a>)
										</>
									)}
								</p>

								<h3>Code</h3>
								<p>
									{deviceInfo.location.code}<br />
									({deviceInfo.location.siteName}
									{', '}{deviceInfo.location.buildingName}
									{deviceInfo.location.floor && (
										<>, Floor {deviceInfo.location.floor}</>
									)}
									{deviceInfo.location.room && (
										<>, Room {deviceInfo.location.room}</>
									)})
								</p>
							</PanelContent>
						</Panel>
					)}
				</PanelGrid>

				{gatewayInfo && (
					<>
						<h3>
							Meters behind this gateway
							<Information>
								Many gateways are protocol converters, allowing devices without
								Ethernet connections (e.g. RS-485) to be accessed over the
								network.  If this gateway is acting as a go-between in order to
								make any of these devices accessible, they will be listed here.
							</Information>
						</h3>
						<DeviceList
							meters={gatewayInfo.meters}
							fnGenerateLink={fnGenerateLink}
						/>
					</>
				)}
			</div>
			<div className="infobox">
				<DeviceInfoBox deviceInfo={deviceInfo} parentGateway={parentGateway} />
			</div>
		</div>
	);
}

export default DeviceView;
