/*
 * P&F DRE web application
 *
 * Popup element used for tooltips and inline error messages.
 */

import * as React from 'react';

import './styles.css';

interface TProps {
	align?: 'middle';
	children: React.ReactNode;
	type: 'tooltip' | 'error';
	visible: boolean;
};

const Popup: React.FC<TProps> = (props) => {
	const alignClass = props.align ? 'align-' + props.align : '';

	return (
		<span className={`Popup ${alignClass}`} style={{
			display: props.visible ? 'inline-block' : 'none',
		}}>
			<span className={`popup-content ${props.type}`}>
				{props.children}
			</span>
		</span>
	);
}

export default Popup;
