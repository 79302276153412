/*
 * P&F DRE web application
 *
 * Render information about a single device, supplied by the parent.
 */

import * as React from 'react';
import {
	Link,
} from 'react-router-dom';

import {
	linkDeviceView,
} from '../../links';
import {
	TDeviceInfo,
	TGatewayInfo,
	TMeterInfo,
} from './DeviceView';

import './styles.css';

interface Props {
	deviceInfo: TDeviceInfo;
	parentGateway: TDeviceInfo | null;
};

const DeviceInfoBox: React.FC<Props> = ({ deviceInfo, parentGateway }) => {
	let gatewayInfo: TGatewayInfo | undefined = undefined;
	let meterInfo: TMeterInfo | undefined = undefined;
	if (deviceInfo.__typename === 'GTGateway') {
		gatewayInfo = deviceInfo as TGatewayInfo;
	} else if (deviceInfo.__typename === 'GTMeter') {
		meterInfo = deviceInfo as TMeterInfo;
	}

	const ipv4Address = gatewayInfo?.ipv4Address;
	const urlWebPage = gatewayInfo?.model?.urlUser;
	const elDeviceWebPage = React.useMemo(() => {
		if (urlWebPage) {
			return (
				<a href={urlWebPage}>{ipv4Address}</a>
			);
		}
		return <i>None</i>;
	}, [
		ipv4Address,
		urlWebPage,
	]);

	return (
		<table className="infoBox">
			<thead>
				<tr>
					<td colSpan={2} className="heading">
						{deviceInfo.model?.brand ?? '(unknown brand)'}
					</td>
				</tr>
				<tr>
					<td colSpan={2} className="heading">
						{deviceInfo.model?.name ?? '(unknown product name)'}
					</td>
				</tr>
			</thead>
			<tbody>
				{gatewayInfo && (
					<>
						<tr>
							<td>MAC address:</td>
							<td>{gatewayInfo.macAddress}</td>
						</tr>
						<tr>
							<td>IP address:</td>
							<td>{gatewayInfo.ipv4Address}</td>
						</tr>
					</>
				)}
				<tr>
					<td>Location:</td>
					<td>
						{deviceInfo.location?.code}<br />
						{deviceInfo.location?.name}<br />
						{deviceInfo.location && deviceInfo.location.coordinates && (
							<>
								📍 <a target="_blank" className="external" href={`https://www.google.com.au/maps/place/${deviceInfo.location.coordinates.latitude},${deviceInfo.location.coordinates.longitude}`}>Google Maps</a>
							</>
						)}
					</td>
				</tr>
				{gatewayInfo && (
					<>
						<tr>
							<td>Data outlet:</td>
							<td>{gatewayInfo.dataOutlet || '?'}</td>
						</tr>
						<tr>
							<td>Network switch:</td>
							<td>{gatewayInfo.switchName || '?'}</td>
						</tr>
						<tr>
							<td>Switch port:</td>
							<td>{gatewayInfo.switchPort || '?'}</td>
						</tr>
					</>
				)}
				<tr>
					<td>Device web page:</td>
					<td>{elDeviceWebPage}</td>
				</tr>
				{parentGateway && (
					<tr>
						<td>Parent gateway:</td>
						<td><Link to={linkDeviceView(parentGateway.id)}>{parentGateway.code}</Link></td>
					</tr>
				)}
			</tbody>
		</table>
	);
}

export default DeviceInfoBox;
