/*
 * P&F DRE web application
 *
 * Display single database connection and a button to disconnect it.
 */

import * as React from 'react';

import { TReadings } from './index';

import './styles.css';

interface Props {
	items: TReadings[];
}

const CollectorActivity: React.FC<Props> = ({
	items,
}) => {
	return (
		<ul className="CollectorActivity">
			{items.map(i => (
				<li key={i.id}>
					<span className="date">{i.metrics.measurement_date}</span>
					<span className="code">{i.code}</span>
					<span className="metrics">P: {i.metrics['P']} W</span>
				</li>
			))}
		</ul>
	);
};

export default CollectorActivity;
