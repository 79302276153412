/*
 * P&F DRE web application
 *
 * Routing for database-related pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageDatabaseSessions from './sessions';
import PageDatabaseJobs from './jobs';

const PageDebug: React.FC = () => (
	<Routes>
		{addProtectedRoute('/sessions/', PageDatabaseSessions)}
		{addProtectedRoute('/jobs/*', PageDatabaseJobs)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageDebug;
