/*
 * P&F DRE web application
 *
 * Content for UI panel.
 */

import * as React from 'react';

interface TProps {
	children: React.ReactNode;
};

const PanelContent: React.FC<TProps> = ({ children }) => (
	<div className="content">
		{children}
	</div>
);

export default PanelContent;
