/*
 * P&F DRE web application
 *
 * Render the supplied list of active database connections.
 */

import * as React from 'react';

import JobListEntry, { TJob } from '../JobListEntry';
import './styles.css';

interface Props {
	jobIdHighlight?: number;
	jobList: TJob[];
	fnRefreshList: Function;
}

export function sortJobs(jobList: TJob[]) {
	jobList.sort(
		// Put the oldest jobs at the top of the list.
		(a, b) => {
			if (a.activeAt && !b.activeAt) return 0;
			else if (!a.activeAt && b.activeAt) return 1;
			else if (a.createdAt < b.createdAt) return 0;
			else if (a.createdAt > b.createdAt) return 1;
			else if (a.idJob < b.idJob) return 0;
			else return 1;
		}
	);

	return jobList;
}

const JobList: React.FC<Props> = ({
	jobList,
	jobIdHighlight,
	fnRefreshList,
}) => {
	const hasJobs = jobList.length > 0;

	const sortedList = React.useMemo(() => (
		// We have to sort a copy of the list - sorting in place throws a TypeError
		// because the prop is apparently immutable.
		sortJobs([...jobList])
	), [
		jobList,
	]);

	return (
		<div className="JobList">
			{hasJobs ? (
				<ul>
					{sortedList.map(j => (
						<JobListEntry
							key={j.idJob}
							job={j}
							highlight={j.idJob === jobIdHighlight}
							fnRefreshList={fnRefreshList}
						/>
					))}
				</ul>
			) : (
				<p>
					No jobs.
				</p>
			)}
		</div>
	);
};

export default JobList;
