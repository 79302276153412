/*
 * P&F DRE web application
 *
 * Page for summarising electric vehicle (EV) charging devices.
 */

import React from 'react';

import Button from '../../../../components/Button';
import { Page, PageHeading, PageContent } from '../../../../components/Page';
import EVChargerList from '../../../../components/EVChargerList';

import './styles.css';

type TRefreshCallback = () => void;

const PageEVSummary: React.FC = () => {
	const [fnRefreshCallback, setFnRefreshCallback] = React.useState<TRefreshCallback>();
	const [refreshing, setRefreshing] = React.useState<boolean>(false);

	// We can't call setFnRefreshCallback(fn) because when we pass a function to
	// setState(), React calls it immediately with the previous state.  So in
	// order to put a callback function into the state, we have to pass setState
	// a function, which returns the function that should go into the state.  We
	// do this inside useCallback() here.
	const setRefreshCallback = React.useCallback((cb: TRefreshCallback) => {
		// Pass in a function that can be called immediately, and returns `cb`.
		setFnRefreshCallback(_prevState => cb);
	}, [
		setFnRefreshCallback,
	]);

	return (
		<Page classSuffix="EVSummary">
			<PageHeading
				title="EV / Summary"
				actions={
					<Button
						type="refresh"
						isActive={refreshing}
						onClick={() => (fnRefreshCallback && fnRefreshCallback())}
					/>
				}
			/>
			<PageContent>
				<div>
					Chargers online: X / Y
				</div>
				<EVChargerList
					setRefreshCallback={setRefreshCallback}
					setRefreshing={setRefreshing}
				/>
			</PageContent>
		</Page>
	);
}

export default PageEVSummary;
