/*
 * P&F DRE web application
 *
 * Page for searching the list of devices.
 */

import * as React from 'react';

const SearchInstructions: React.FC = () => (
	<p>
		Enter the criteria for the devices you wish to find.
	</p>
);

export default SearchInstructions;
