/*
 * P&F DRE web application
 *
 * Error page, for unhandled errors.
 */

import React from 'react';

import PageErrorException from './exception';

interface TProps {
	error: Error;
}

const PageErrorUnexpected: React.FC<TProps> = (props) => (
	<PageErrorException
		heading="Unhandled exception"
		title="Unexpected error"
		error={props.error}
	>
		<p>
			Something just went wrong, and this web site did not have code to handle
			the error gracefully.  The technical detail of the error is below, in
			case it provides any hint about what went wrong.
			You can use the back button to return to where you were previously.
		</p>
	</PageErrorException>
);

export default PageErrorUnexpected;
