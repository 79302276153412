/*
 * P&F DRE web application
 *
 * Main home page/starting point.
 */

import React from 'react';

import { Page, PageHeading, PageContent } from '../../components/Page';

import './styles.css';

const PageHome: React.FC = () => (
	<Page classSuffix="Home">
		<PageHeading title="Overview" />
		<PageContent>
			<div className="status">
				<div>
					<h1>BESS</h1>
				</div>
				<div>
					<h1>PV</h1>
				</div>
			</div>
		</PageContent>
	</Page>
);

export default PageHome;
