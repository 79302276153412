/*
 * P&F DRE web application
 *
 * Standard page styling.
 */

import * as React from 'react';

import './styles.css';

interface TProps {
	children: React.ReactNode;
	classSuffix: string;
}

const Page: React.FC<TProps> = (props) => (
	<div className={`Page Page${props.classSuffix}`}>
		{props.children}
	</div>
);


export default Page;
