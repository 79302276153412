/*
 * P&F DRE web application
 *
 * Information icon that can be hovered over for more detail.
 */

import * as React from 'react';

import Popup from '../Popup';
import './styles.css';

interface TProps {
	children: React.ReactNode;
};

const Information: React.FC<TProps> = ({ children }) => (
	<div className="Information">
		<i>🛈</i>
		<div className="info-popup">
			<Popup visible={true} type="tooltip" align="middle">
				{children}
			</Popup>
		</div>
	</div>
);

export default Information;
