/*
 * P&F DRE web application
 *
 * Request list of database connections from the API and render.
 */

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
	DeviceLogsQueryVariables,
	useDeviceLogsQuery,
} from '../../generated/graphql';
import { APIError, ErrorMessage } from '../ErrorMessage';
import LogList from './LogList';
import Loading from '../Loading';

type TRefreshCallback = () => void;
type TPollCallback = () => void;

interface Props {
	idDevice: number;
	source: string;
};

const DeviceLogsContainer: React.FC<Props> = ({
	idDevice,
	source,
}) => {
	const variables: DeviceLogsQueryVariables = {
		idDevice,
		source,
	};

	const { data, error, loading, refetch, networkStatus } = useDeviceLogsQuery({
		notifyOnNetworkStatusChange: true, // ask networkStatus to be updated
		fetchPolicy: 'no-cache',
		variables,
	});

	const refetchPending = networkStatus === 4;

	// Only show loading animation on initial fetch, not when refreshing, unless
	// we've got no data at all to show while refreshing.
	if (loading && (!refetchPending || !data)) {
		return <Loading />;
	}

	if (error) {
		return <APIError apolloError={error} />;
	}

	if (!data) {
		return <ErrorMessage>Empty response from server.</ErrorMessage>;
	}

	return (
		<>
			{error && (
				<APIError apolloError={error} />
			)}
			<LogList
				logs={data.logs}
				fnRefreshList={refetch}
			/>
		</>
	);
};

export default DeviceLogsContainer;
