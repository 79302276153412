/*
 * P&F DRE web application
 *
 * Display a single database connection passed in as a prop, and call the
 * termination API if requested.
 */

import * as React from 'react';
import {
	useJobRetryMutation,
} from '../../generated/graphql';
import JobListEntry, {
	TJob,
	TFnRetry,
} from './JobListEntry';

export type { TJob } from './JobListEntry';

interface Props {
	job: TJob;
	highlight?: boolean;
	fnRefreshList: Function;
};

const JobListEntryContainer: React.FC<Props> = ({
	job,
	highlight,
	fnRefreshList,
}) => {
	const [retryJob, { error, loading }] = useJobRetryMutation();

	const fnRetry: TFnRetry = React.useCallback(async () => {
		// Call the GraphQL mutate function.
		if (job && job.idJob) {
			await retryJob({
				variables: {
					idJob: job.idJob,
				}
			});
			await fnRefreshList();
		} else {
			console.error('Invalid job object:', job);
		}
	}, [
		fnRefreshList,
		job,
		retryJob,
	]);

	return (
		<JobListEntry
			job={job}
			isLoading={loading}
			error={error}
			fnRetry={fnRetry}
			highlight={highlight}
		/>
	);
};

export default JobListEntryContainer;
