/*
 * P&F DRE web application
 *
 * Routing for dashboard pages.
 */

import React from 'react';
import {
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageDashboardsViewers from './viewers';

const PageDashboards: React.FC = () => (
	<Routes>
		{addProtectedRoute('/viewers/*', PageDashboardsViewers)}
		<Route path="" element={<Navigate to="./viewers" replace />} />
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageDashboards;
