/*
 * P&F DRE web application
 *
 * Page for summarising solar panel (PV) infrastructure.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import PagePVSummary from './summary';

const PagePV: React.FC = () => (
	<Routes>
		<Route path="" element={<PagePVSummary />} />
		<Route path="*" element={<p>Invalid PV URL.</p>} />
	</Routes>
);

export default PagePV;
