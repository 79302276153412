/*
 * P&F DRE web application
 *
 * Request list of database connections from the API and render.
 */

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
	useJobListQuery,
	useJobPollMutation,
} from '../../generated/graphql';
import { APIError, ErrorMessage } from '../ErrorMessage';
import JobList from './JobList';
import Loading from '../Loading';

type TRefreshCallback = () => void;
type TPollCallback = () => void;

interface Props {
	setRefreshCallback: (cb: TRefreshCallback) => void;
	setRefreshing: (refreshing: boolean) => void;
	setPollCallback: (cb: TPollCallback) => void;
	setPolling: (refreshing: boolean) => void;
	jobIdHighlight?: number;
};

const JobListContainer: React.FC<Props> = ({
	setRefreshCallback,
	setRefreshing,
	setPollCallback,
	setPolling,
}) => {
	const { data, error, loading, refetch, networkStatus } = useJobListQuery({
		notifyOnNetworkStatusChange: true, // ask networkStatus to be updated
		fetchPolicy: 'no-cache',
	});

	// Extract the ?highlight=X from the end of the URL.
	const { search } = useLocation();
	const urlQuery = React.useMemo(() => new URLSearchParams(search), [search]);
	const highlight = React.useMemo(() => (
		parseInt(urlQuery.get('highlight') ?? '', 10)
	), [
		urlQuery,
	]);

	const refetchPending = networkStatus === 4;

	// Job polling
	const [pollJob, { error: errorPolling, loading: loadingPolling }] = useJobPollMutation();
	const fnPoll: TPollCallback = React.useCallback(async () => {
		// Call the GraphQL mutate function.
		await pollJob();
	}, [
		pollJob,
	]);

	// Pass the refresh function back to the parent component.
	React.useEffect(() => {
		setRefreshCallback(refetch);
		setPollCallback(fnPoll);
	}, [
		// It will get set again any time these variables change.
		refetch,
		setRefreshCallback,
		fnPoll,
		setPollCallback,
	]);

	// Notify the parent component when we're doing a refresh, so it can update
	// the button activity state.
	React.useEffect(() => {
		setRefreshing(refetchPending);
		setPolling(loadingPolling);
	}, [
		refetchPending,
		setRefreshing,
		loadingPolling,
		setPolling,
	]);

	// Only show loading animation on initial fetch, not when refreshing, unless
	// we've got no data at all to show while refreshing.
	if (loading && (!refetchPending || !data)) {
		return <Loading />;
	}

	if (!data) {
		return <ErrorMessage>Empty response from server.</ErrorMessage>;
	}

	return (
		<>
			{error && (
				<APIError apolloError={error} />
			)}
			{errorPolling && (
				<APIError apolloError={errorPolling} />
			)}
			<JobList
				jobList={data.jobs}
				jobIdHighlight={highlight}
				fnRefreshList={refetch}
			/>
		</>
	);
};

export default JobListContainer;
