/*
 * P&F DRE web application
 *
 * Display single database connection and a button to disconnect it.
 */

import * as React from 'react';
import { ApolloError } from '@apollo/client';
import Moment from 'moment';
import { DatabaseConnectionListQuery } from '../../generated/graphql';
import Button from '../Button';
import './styles.css';

export type TConnection = DatabaseConnectionListQuery['databaseConnections'][0];
export type TFnTerminate = () => void;

interface Props {
	connection: TConnection;
	fnTerminate: TFnTerminate;
	isLoading?: boolean;
	error?: ApolloError;
}

function icon(cid: string) {
	return {
		'P': (<i title="Primary (read/write) database">📝</i>),
		'R': (<i title="Replica (read only) database">📘</i>),
	}[cid[0]] ?? (<i></i>);
}

const DatabaseConnectionEntry: React.FC<Props> = ({
	connection: c,
	fnTerminate,
	isLoading,
	error,
}) => {

	// Convert the connection time to "X minutes ago".
	const connectedAt = c.connectedAt;
	const strConnectedAt = React.useMemo<string>(() => {
		if (connectedAt) {
			return Moment(connectedAt).fromNow();
		} else {
			return '-';
		}
	}, [
		connectedAt,
	]);

	return (
		<li className="DatabaseConnectionListEntry">
			{icon(c.idConnection)}
			<div className="left">
				<h4>
					{c?.application ?? (
						<i>No application name provided</i>
					)}
				</h4>
				<div className="subtitle">
					{error ? (
						<div className="error">
							{error.message}
						</div>
					) : (
						<>
							<div className="connectedAt" title="Time of connection">
								{strConnectedAt}
							</div>
							<div className="ip" title="Connection origin address">{c?.ip ?? '-'}</div>
							<div className="state" title="PostgreSQL connection state">{c?.state ?? '-'}</div>
							<div className="cid" title="PostgreSQL connection PID">{c?.idConnection ?? '-'}</div>
						</>
					)}
				</div>
			</div>
			<Button
				type="terminate"
				isActive={isLoading}
				onClick={fnTerminate}
			/>
		</li>
	);
};

export default DatabaseConnectionEntry;
