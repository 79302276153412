/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	parameters: any;
	setParameters: React.SetStateAction<any>;
}

const JobParametersRecalculateVirtualMeters: React.FC<Props> = ({ parameters, setParameters }) => {
	return (
		<div className="parameters">
			<table className="fields">
				<tr>
					<td className="name">
						<label htmlFor="startTime">Start date/time:</label>
					</td>
					<td className="value">
						<input
							name="startTime"
							type="datetime-local"
							step="1"
							onChange={ev => setParameters({
								...parameters,
								startTime: ev.target.value,
							})}
						/>
						<span>
							This is the first timestamp to read.  This timestamp won't be
							updated though, as no previous reading will be available.  It
							will be the next timestamp available after this moment that will
							be the first to be updated.
						</span>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="endTime">End date/time:</label>
					</td>
					<td className="value">
						<input
							name="endTime"
							type="datetime-local"
							step="1"
							onChange={ev => setParameters({
								...parameters,
								endTime: ev.target.value,
							})}
						/>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="codeVMDevices">Virtual meter device codes:</label>
					</td>
					<td className="value">
						<input
							name="codeVMDevices"
							type="text"
							onChange={ev => setParameters({
								...parameters,
								codeVMDevices: (
									(ev.target.value && ev.target.value.length > 0)
										? ev.target.value.split(',')
										: undefined
								),
							})}
						/>
						<span>
							Optional value.  A comma-separated list of virtual meter device
							codes to update, e.g. <samp>V0.01,V0.02</samp>.  If this and the
							following feeder device code fields are left blank, data from all
							virtual meters will be recalculated.
						</span>
					</td>
				</tr>
				<tr>
					<td className="name">
						<label htmlFor="codeFeederDevices">Feeder device codes:</label>
					</td>
					<td className="value">
						<input
							name="codeFeederDevices"
							type="text"
							onChange={ev => setParameters({
								...parameters,
								codeFeederDevices: (
									(ev.target.value && ev.target.value.length > 0)
										? ev.target.value.split(',')
										: undefined
								),
							})}
						/>
						<span>
							Optional value.  A comma-separated list of device codes that
							contribute to a VM, e.g. <samp>78.08,78.09</samp>.  These devices
							won't be touched, but any VMs they contribute to will be
							recalculated.  If both this and the previous code field is left
							blank, data from all virtual meters is recalculated.
						</span>
					</td>
				</tr>
			</table>
		</div>
	);
};

export default JobParametersRecalculateVirtualMeters;
