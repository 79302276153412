/*
 * P&F DRE web application
 *
 * Display single database connection and a button to disconnect it.
 */

import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { EvChargerStatusQuery } from '../../generated/graphql';
import Button from '../Button';
import './styles.css';

export type TConnectorStatus = EvChargerStatusQuery['chargerStatus'][0]['connectors'][0];
export type TFnCallback = () => void;

interface Props {
	connectorStatus: TConnectorStatus;
	fnStartCharge: TFnCallback;
	fnStopCharge: TFnCallback;
	fnStartDischarge: TFnCallback;
	isPendingStartCharge?: boolean;
	isPendingStopCharge?: boolean;
	isPendingStartDischarge?: boolean;
	error?: ApolloError;
}

const EVChargerEntry: React.FC<Props> = ({
	connectorStatus: c,
	fnStartCharge,
	fnStopCharge,
	fnStartDischarge,
	isPendingStartCharge,
	isPendingStopCharge,
	isPendingStartDischarge,
	error,
}) => {
	let icon, status;
	if (c.isFault) {
		status = 'Faulty';
		icon = '⛽💥';
	} else if (c.isCharging) {
		status = 'Charging';
		icon = '🚙⚡';
	} else if (c.isPlugged) {
		status = 'Plugged in, not charging';
		icon = '🚙🔋';
	} else {
		status = 'Available for use';
		icon = '⛽🅿️️';
	}

	return (
		<li className="EVChargerListEntry">
			<i title={status}>{icon}</i>
			<div className="left">
				<h4>
					{c.deviceCode}
				</h4>
				<div className="subtitle">
					{error ? (
						<div className="error">
							{error.message}
						</div>
					) : (
						<div>
							{status}
						</div>
					)}
				</div>
			</div>
			<Button
				type="startCharge"
				isActive={isPendingStartCharge}
				onClick={fnStartCharge}
				enabled={c.isPlugged}
			/>
			<Button
				type="stopCharge"
				isActive={isPendingStopCharge}
				onClick={fnStopCharge}
				enabled={c.isCharging}
			/>
			<Button
				type="startDischarge"
				isActive={isPendingStartDischarge}
				onClick={fnStartDischarge}
				enabled={c.isPlugged}
			/>
		</li>
	);
};

export default EVChargerEntry;
