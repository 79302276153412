/*
 * P&F DRE web application
 *
 * Page for adding a new job/task to the DB.
 */

import * as React from 'react';

import { Page, PageHeading, PageContent } from '../../../../components/Page';
import JobNew from '../../../../components/JobNew';

const PageDatabaseJobsNew: React.FC = () => {
	return (
		<Page classSuffix="DatabaseJobsNew">
			<PageHeading
				title="New Job"
			/>
			<PageContent>
				<JobNew />
			</PageContent>
		</Page>
	);
}

export default PageDatabaseJobsNew;
