/*
 * P&F DRE web application
 *
 * Page for showing active connections to the dashboard websocket.
 */

import * as React from 'react';

import Button from '../../../components/Button';
import WebsocketConnectionList from '../../../components/WebsocketConnectionList';
import { Page, PageHeading, PageContent } from '../../../components/Page';

type TRefreshCallback = () => void;

const PageDashboardsViewers: React.FC = () => {
	const [fnRefreshCallback, setFnRefreshCallback] = React.useState<TRefreshCallback>();
	const [refreshing, setRefreshing] = React.useState<boolean>(false);

	// We can't call setFnRefreshCallback(fn) because when we pass a function to
	// setState(), React calls it immediately with the previous state.  So in
	// order to put a callback function into the state, we have to pass setState
	// a function, which returns the function that should go into the state.  We
	// do this inside useCallback() here.
	const setRefreshCallback = React.useCallback((cb: TRefreshCallback) => {
		// Pass in a function that can be called immediately, and returns `cb`.
		setFnRefreshCallback(_prevState => cb);
	}, [
		setFnRefreshCallback,
	]);

	return (
		<Page classSuffix="DashboardSessions">
			<PageHeading
				title="Active Dashboard Connections"
				actions={
					<Button
						type="refresh"
						isActive={refreshing}
						onClick={() => (fnRefreshCallback && fnRefreshCallback())}
					/>
				}
			/>
			<PageContent>
				<p>
					These clients are currently connected to the Websocket that is
					providing live data to the dashboards.  Generally the connections
					time out after 15 minutes and are re-established if the dashboard
					is still being viewed.
				</p>
				<WebsocketConnectionList
					setRefreshCallback={setRefreshCallback}
					setRefreshing={setRefreshing}
				/>
			</PageContent>
		</Page>
	);
}

export default PageDashboardsViewers;
