/*
 * P&F DRE web application
 *
 * Render a supplied list of devices.
 */

import * as React from 'react';
import {
	Link,
} from 'react-router-dom';
import './styles.css';

interface DeviceInfo {
	id: number;
	code: string;
	name?: string | null;
	lastError?: {
		severity: string;
	} | null;
};

interface MeterInfo extends DeviceInfo {
};

export interface GatewayInfo extends DeviceInfo {
	meters: MeterInfo[];
};

interface Props {
	gateways?: GatewayInfo[];
	meters?: MeterInfo[];
	fnGenerateLink: (id: number, code: string) => string;
};

// Convert a severity enum into an icon.
function severityMap(s?: string | null) {
	return {
		'OK': {
			icon: '✅',
			text: 'Device has responded with data minutes ago.',
		},
		'ADV': {
			icon: 'ℹ️',
			text: 'Device has a possible low-priority issue.',
		},
		'IMP': {
			icon: '⚠️',
			text: 'Device is experiencing an important but non-critical problem.',
		},
		'CRI': {
			icon: '🛑',
			text: 'Device is not providing any data due to a critical error.',
		},
	}[s ?? 'OK'] ?? {
		icon: '❓',
		text: 'Unknown device status: ' + s,
	};
}

interface MeterProps {
	meters: MeterInfo[];
	fnGenerateLink: (id: number, code: string) => string;
};

const MeterList: React.FC<MeterProps> = ({ meters, fnGenerateLink }) => (
	<>
		{meters?.map(m => {
			const mHref = fnGenerateLink(m.id, m.code);
			const { icon: sevIcon, text: sevText } = severityMap(m.lastError?.severity);
			return (
				<tr className="meter" key={'meter' + m.id}>
					<td className="icon" title="Type of device: Meter"><Link to={mHref}>📟</Link></td>
					<td className="id"><Link to={mHref}>{m.id}</Link></td>
					<td className="stat"><Link to={mHref} title={sevText}>{sevIcon}</Link></td>
					<td className="code"><Link to={mHref}>{m.code}</Link></td>
					<td className="name"><Link to={mHref}>{m.name}</Link></td>
				</tr>
			);
		})}
	</>
);

interface GatewayProps {
	gateways: GatewayInfo[];
	fnGenerateLink: (id: number, code: string) => string;
};

const GatewayList: React.FC<GatewayProps> = ({ gateways, fnGenerateLink }) => (
	<>
		{gateways.map(g => {
			const gHref = fnGenerateLink(g.id, g.code);
			const { icon: sevIcon, text: sevText } = severityMap(g.lastError?.severity);
			return (
				<React.Fragment key={'gateway' + g.id}>
					<tr className="pad">
						<td colSpan={5}></td>
					</tr>
					<tr className="gateway">
						<td className="icon" title="Type of device: Gateway"><Link to={gHref}>📡</Link></td>
						<td className="id"><Link to={gHref}>{g.id}</Link></td>
						<td className="stat"><Link to={gHref} title={sevText}>{sevIcon}</Link></td>
						<td className="code"><Link to={gHref}>{g.code}</Link></td>
						<td className="name"><Link to={gHref}>{g.name}</Link></td>
					</tr>
					<MeterList
						meters={g.meters}
						fnGenerateLink={fnGenerateLink}
					/>
				</React.Fragment>
			);
		})}
	</>
);

const DeviceList: React.FC<Props> = ({ gateways, meters, fnGenerateLink }) => (
	<div className="DeviceList">
		<table className="rowHover">
			<thead>
				<tr>
					<th className="icon"></th>
					<th className="id">ID</th>
					<th className="stat">Status</th>
					<th className="code">Code</th>
					<th className="name">Name</th>
				</tr>
			</thead>
			<tbody>
				<GatewayList
					gateways={gateways ?? []}
					fnGenerateLink={fnGenerateLink}
				/>
				<MeterList
					meters={meters ?? []}
					fnGenerateLink={fnGenerateLink}
				/>
				{(((gateways?.length ?? 0) + (meters?.length ?? 0)) === 0) && (
					<tr>
						<td colSpan={5} className="no-devices">
							No devices.
						</td>
					</tr>
				)}
			</tbody>
		</table>
	</div>
);

export default DeviceList;
