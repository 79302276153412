/*
 * P&F DRE web application
 *
 * Render the supplied list of active database connections.
 */

import * as React from 'react';

import JobListEntry, { TJob } from '../JobListEntry';
import './styles.css';

interface TLogMessage {
	dtLogged: string;
	category: string;
	message: string;
};
interface Props {
	logs: TLogMessage[];
	fnRefreshList: Function;
}

const LogList: React.FC<Props> = ({
	logs,
	fnRefreshList,
}) => {
	const hasMessages = logs.length > 0;

	return (
		<div className="LogList">
			{hasMessages ? (
				<table>
					{logs.map(ev => (
						<tr key={ev.dtLogged + ev.message}>
							<td>
								{ev.dtLogged}
							</td>
							<td>
								{ev.category}
							</td>
							<td>
								{ev.message}
							</td>
						</tr>
					))}
				</table>
			) : (
				<p>
					No log messages found.
				</p>
			)}
		</div>
	);
};

export default LogList;
