/*
 * P&F DRE web application
 *
 * Routing for asset pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageEV from './ev';
import PagePV from './pv';

const PageAssets: React.FC = () => (
	<Routes>
		{addProtectedRoute('/ev/*', PageEV)}
		{addProtectedRoute('/pv/*', PagePV)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageAssets;
