import * as React from 'react';

import Button from '../Button';
import './styles.css';

export interface DeviceSearchCriteria {
	scID?: string;
	scCode?: string;
	scName?: string;
};

interface Props extends DeviceSearchCriteria {
	onSearch: (criteria: any) => void;
};

const DeviceSearchFields: React.FC<Props> = (props) => {

	const {
		onSearch,
		scID,
		scCode,
		scName,
	} = props;

	const [fieldID, setFieldID] = React.useState(scID || '');
	const [fieldCode, setFieldCode] = React.useState(scCode || '');
	const [fieldName, setFieldName] = React.useState(scName || '');

	// Sync the props to the state, so that when the props are updated (e.g. by
	// using the back/forward browser buttons), the search fields update too.
	React.useEffect(() => {
		setFieldID(scID || '');
		setFieldCode(scCode || '');
		setFieldName(scName || '');
	}, [
		scID,
		scCode,
		scName,
	]);

	const cbOnSearch = React.useCallback((
		event: React.FormEvent<HTMLFormElement | HTMLButtonElement>
	): void => {
		event.preventDefault();

		// Only add items that are non-null and non-undefined, otherwise we end
		// up with things like `?name=undefined` in the URL.
		let criteria: any = {};
		if (fieldID) criteria.id = fieldID;
		if (fieldCode) criteria.code = fieldCode;
		if (fieldName) criteria.name = fieldName;

		onSearch(criteria);
	}, [
		onSearch,
		fieldID,
		fieldCode,
		fieldName,
	]);

	return (
		<form className="DeviceSearchFields" onSubmit={cbOnSearch}>
			<span className="field id">
				<label htmlFor="id">DRE Device ID</label>
				<input
					id="id"
					type="text"
					placeholder="123"
					value={fieldID}
					onChange={(event) => setFieldID(event.target.value)}
				/>
			</span>

			<span className="field code">
				<label htmlFor="code">Code</label>
				<input
					id="code"
					type="text"
					placeholder="42.16"
					value={fieldCode}
					onChange={(event) => setFieldCode(event.target.value)}
				/>
			</span>

			<span className="field name">
				<label htmlFor="name">Name</label>
				<input
					id="name"
					type="text"
					placeholder=""
					value={fieldName}
					onChange={(event) => setFieldName(event.target.value)}
				/>
			</span>

			<span className="field-action submit">
				<Button type="search" />
			</span>
		</form>
	);
}

export default DeviceSearchFields;
