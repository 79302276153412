/*
 * P&F DRE web application
 *
 * Generic page for URLs that are not routed to a Page.
 */

import * as React from 'react';

import { Page, PageHeading, PageContent } from '../../../components/Page';

const InvalidURL: React.FC = () => (
	<Page classSuffix="InvalidURL">
		<PageHeading title="404 Not Found" />
		<PageContent>
			<p>
				This URL does not point to a valid address.
			</p>
		</PageContent>
	</Page>
);


export default InvalidURL;
