/*
 * P&F DRE web application
 *
 * Error page, for unhandled errors.
 */

import React from 'react';

import Button from '../../../components/Button';
import { Page, PageHeading, PageContent } from '../../../components/Page';

const PageDebugErrors: React.FC = () => {
	const [throwException, setThrowException] = React.useState<boolean>(false);

	if (throwException) {
		throw new Error('This is a test error for checking error handling during development.');
	}

	return (
		<Page classSuffix="DebugUI">
			<PageHeading title="Debug / Errors" />
			<PageContent>
				<p>
					The navigation should not break when an exception is thrown within a
					page and not handled.
				</p>
				<Button type="terminate" onClick={() => setThrowException(true)}>
					Throw unhandled exception
				</Button>
			</PageContent>
		</Page>
	);
}

export default PageDebugErrors;
