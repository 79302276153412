/*
 * P&F DRE web application
 *
 * Menu displayed on the left of each page.
 */

import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

// TEMP: Replace this with a role wrapper when SSO is done.
const IfDeveloper: React.FC<any> = (props) => (
	<>
		{props.children}
	</>
);

const SidebarContainer: React.FC = () => (
	<div className="Sidebar">
		<h1><Link to="/">DRE</Link></h1>
		<ul>
			<li>
				<p>Infrastructure</p>
				<ul>
					<li><Link to="devices/search"><i>📠</i>Devices</Link></li>
					<li><Link to="buildings"><i>🏠</i>Buildings</Link></li>
				</ul>
			</li>
			<li>
				<p>Assets</p>
				<ul>
					<li><Link to="assets/pv"><i>🌞</i>Solar</Link></li>
					<li><Link to="assets/bess"><i>🔋</i>Battery</Link></li>
					<li><Link to="assets/ev"><i>⛽</i>EV Chargers</Link></li>
				</ul>
			</li>
			<li>
				<p>System</p>
				<ul>
					<li><Link to="system/collector"><i>📷</i>Collector</Link></li>
					<li><Link to="db/sessions"><i>💾</i>Database</Link></li>
					<li><Link to="db/jobs"><i>🏗️</i>Jobs</Link></li>
					<li><Link to="dashboards"><i>🖥️</i>Dashboards</Link></li>
					<li>
						<a
							href="https://zabbix-ng.its.uq.edu.au/grafana/d/9uyHqHjWk/dre-backend?orgId=1&from=now-3h&to=now"
							target="_blank"
							title="Grafana instance run by ITS Infrastructure Systems to monitor TimescaleDB EC2s"
						>
							<i>📈</i>DB Status
						</a>
					</li>
				</ul>
			</li>
			<IfDeveloper>
				<li>
					<p>Developer</p>
					<ul>
						<li><Link to="debug"><i>🚧</i>Debug</Link></li>
					</ul>
				</li>
			</IfDeveloper>
		</ul>
	</div>
);

export default SidebarContainer;
