/*
 * P&F DRE web application
 *
 * Loading/waiting animation.
 */

import * as React from 'react';

import './styles.css';

const Spinner: React.FC = () => (
	<div className="spinner">
		🚀
	</div>
);

export default Spinner;
