import React from 'react';
import './styles.css';

const Loading = () => (
	<div className="Loading">
		<div className="background">
			<div className="animation">
				<div className="fadeout">
				</div>
			</div>
		</div>
	</div>
);


export default Loading;
