/*
 * P&F DRE web application
 *
 * Action area for UI panel.
 */

import * as React from 'react';

interface TProps {
	children: React.ReactNode;
};

const PanelActions: React.FC<TProps> = ({ children }) => (
	<div className="title-right">
		{children}
	</div>
);

export default PanelActions;
