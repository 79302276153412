/*
 * P&F DRE web application
 *
 * Display a button to trigger a refresh of the Collector's device list.
 */

import * as React from 'react';
import {
	useCollectorDeviceRefreshMutation,
} from '../../generated/graphql';

import Button from '../Button';
import { APIError } from '../ErrorMessage';

import './styles.css';

const CollectorDeviceRefresh: React.FC = () => {
	const [deviceRefresh, { error, loading }] = useCollectorDeviceRefreshMutation();
	const [refreshResult, setRefreshResult] = React.useState<string | null>(null);

	const performRefresh = React.useCallback(async () => {
		setRefreshResult(null);
		const res = await deviceRefresh();
		console.log(res);
		if (res?.data?.collectorRefreshDevices === true) {
			setRefreshResult('Refresh successful');
		} else {
			setRefreshResult('Refresh failed, no reason given.');
		}
	}, [
		deviceRefresh,
		setRefreshResult,
	]);

	return (
		<div className="CollectorDeviceRefresh">
			<Button
				type="refresh"
				isActive={loading}
				onClick={performRefresh}
				title="Tell the Collector to check the database for any changes to devices."
			>
				Refresh device list
			</Button>
			{(!error && refreshResult) && (
				<span>{refreshResult}</span>
			)}
			{error && (
				<APIError apolloError={error} />
			)}
		</div>
	);
};

export default CollectorDeviceRefresh;
