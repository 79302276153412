/*
 * P&F DRE web application
 *
 * Request information about a device from the API and render.
 */

import * as React from 'react';
import {
	useDeviceViewQuery,
	DeviceViewQueryVariables,
	useDeviceUpdateMutation,
	DeviceViewDocument,
	DeviceUpdateMutationVariables,
} from '../../generated/graphql';

import DeviceView, { TDeviceInfo, TGatewayInfo } from './DeviceView';
import Loading from '../Loading';
import { APIError } from '../ErrorMessage';

export type { TDeviceInfo } from './DeviceView';

interface TProps {
	id: number;
	fnSetDeviceInfo: (di: TDeviceInfo) => void;
}

const DeviceViewContainer: React.FC<TProps> = ({ id, fnSetDeviceInfo }) => {
	const variables: DeviceViewQueryVariables = {
		idDevices: [id],
	};

	const { data, error, loading } = useDeviceViewQuery({ variables });
	const [mutateDevice, { data: dataUpdate, error: errorUpdate, loading: loadingUpdate }] = useDeviceUpdateMutation({
		refetchQueries: [
			{
				query: DeviceViewDocument,
				variables,
			},
		],
	});

	// Find the device, as the query might return a device inside a gateway,
	// and previously cached meters.
	interface TDeviceView {
		deviceInfo: TDeviceInfo | null;
		parentGateway: TDeviceInfo | null;
	};
	let device = React.useMemo((): TDeviceView => {
		for (const d of data?.deviceDetail ?? []) {
			if (d.id === id) {
				return {
					deviceInfo: d as TDeviceInfo,
					parentGateway: null,
				};
			}
			if (d.__typename === 'GTGateway') {
				const g = d as TGatewayInfo;
				for (const m of g.meters ?? []) {
					if (m.id === id) {
						return {
							deviceInfo: m,
							parentGateway: g,
						};
					}
				}
			}
		}
		return {
			deviceInfo: null,
			parentGateway: null,
		};
	}, [
		data,
		id,
	]);

	const updateDeviceInfo = React.useCallback(async (newData: TDeviceInfo) => {
		await mutateDevice({
			variables: {
				idDevice: id,
				deviceInfo: {
					...(newData as unknown as DeviceUpdateMutationVariables),
					code: newData.code,
					name: newData.name,
				},
			},
		});
	}, [
		id,
		mutateDevice,
	]);

	React.useEffect(() => {
		if (device.deviceInfo) fnSetDeviceInfo(device.deviceInfo);
	}, [
		device,
		fnSetDeviceInfo,
	]);

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <APIError apolloError={error} />;
	}

	if (!device?.deviceInfo) {
		return <div>Device ID {id} not found.</div>;
	}

	return (
		<DeviceView
			deviceInfo={device.deviceInfo}
			parentGateway={device.parentGateway}
			fnUpdateDeviceInfo={updateDeviceInfo}
		/>
	);
};

export default DeviceViewContainer;
