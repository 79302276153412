/*
 * P&F DRE web application
 *
 * Render the supplied list of active database connections.
 */

import * as React from 'react';

import EVChargerListEntry from '../EVChargerListEntry';
import { EvChargerStatusQuery } from '../../generated/graphql';

import './styles.css';

export type TChargerStatus = EvChargerStatusQuery['chargerStatus'][0];

interface Props {
	chargerStatus: TChargerStatus[];
}

const EVChargerList: React.FC<Props> = ({ chargerStatus }) => {
	const hasConnectedChargers = chargerStatus.length > 0;

	const sortedList = React.useMemo(() => (
		// We have to sort a copy of the list - sorting in place throws a TypeError
		// because the prop is apparently immutable.
		[...chargerStatus].sort(
			// Put the earliest connections at the top of the list.
			(a, b) => (a.deviceCode < b.deviceCode) ? 0 : 1
		)
	), [
		chargerStatus,
	]);

	return (
		<div className="EVChargerList">
			{hasConnectedChargers ? (
				<ul>
					{sortedList.map(ch => (
						ch.connectors.map(cn => (
							<EVChargerListEntry
								key={cn.idDevice}
								connectorStatus={cn}
							/>
						))
					))}
				</ul>
			) : (
				<p>
					No EV chargers connected.
				</p>
			)}
		</div>
	);
};

export default EVChargerList;
