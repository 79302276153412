/*
 * P&F DRE web application
 *
 * Wrap the given component in an error boundary, so any unhandled exceptions
 * within it won't affect parent components.
 *
 * This is usually used in a <Route /> so that any exceptions on pages won't
 * break the navigation, and the back button can still be used.
 */

import * as React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundary from '../../components/ErrorBoundary';
import PageErrorUnexpected from './unexpected';

// If a Page component has an unhandled error, we will render it here.
const renderError = (error: Error) => (
	<PageErrorUnexpected error={error} />
);

// We MUST specify a `key` on the ErrorBoundary, otherwise after an error is
// caught, using the browser back button just replaces it with an ErrorBoundary
// from another component, which React can't tell apart, so it doesn't
// re-render, and thus the error remains visible.  By adding a `key` prop,
// React can tell each Route's ErrorBoundary apart, and correctly re-render
// them when navigating after an error.
const addProtectedRoute = (path: string, PageImplementation: React.ComponentType, key?: string): React.ReactNode => (
	<Route path={path} element={
		<ErrorBoundary renderError={renderError} key={path}>
			<PageImplementation />
		</ErrorBoundary>
	} />
);

export { addProtectedRoute };
