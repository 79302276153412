/*
 * P&F DRE web application
 *
 * Request list of database connections from the API and render.
 */

import * as React from 'react';
import {
	useEvChargerStatusQuery,
} from '../../generated/graphql';
import { APIError, ErrorMessage } from '../ErrorMessage';
import EVChargerList from './EVChargerList';
import Loading from '../Loading';

type TRefreshCallback = () => void;

interface Props {
	setRefreshCallback: (cb: TRefreshCallback) => void;
	setRefreshing: (refreshing: boolean) => void;
};

const EVChargerListContainer: React.FC<Props> = ({
	setRefreshCallback,
	setRefreshing,
}) => {
	const { data, error, loading, refetch, networkStatus } = useEvChargerStatusQuery({
		notifyOnNetworkStatusChange: true, // ask networkStatus to be updated
		fetchPolicy: 'no-cache',
	});

	const refetchPending = networkStatus === 4;

	// Pass the refresh function back to the parent component.
	React.useEffect(() => {
		setRefreshCallback(refetch);
	}, [
		// It will get set again any time these variables change.
		refetch,
		setRefreshCallback,
	]);

	// Notify the parent component when we're doing a refresh, so it can update
	// the button activity state.
	React.useEffect(() => {
		setRefreshing(refetchPending);
	}, [
		refetchPending,
		setRefreshing,
	]);

	// Only show loading animation on initial fetch, not when refreshing.
	if (loading && !refetchPending) {
		return <Loading />;
	}

	if (error) {
		return <APIError apolloError={error} />;
	}

	if (!data) {
		return <ErrorMessage>Empty response from server.</ErrorMessage>;
	}

	return (
		<>
			{error && (
				<APIError apolloError={error} />
			)}
			<EVChargerList
				chargerStatus={data.chargerStatus}
			/>
		</>
	);
};

export default EVChargerListContainer;
