/*
 * P&F DRE web application
 *
 * Routing for development debugging pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import InvalidURL from '../error/invalid-url';
import { addProtectedRoute } from '../error/addProtectedRoute';

import PageDebugErrors from './errors';
import PageDebugSummary from './summary';
import PageDebugUI from './ui';

const PageDebug: React.FC = () => (
	<Routes>
		{addProtectedRoute('/ui', PageDebugUI)}
		{addProtectedRoute('/errors', PageDebugErrors)}
		{addProtectedRoute('/', PageDebugSummary)}
		<Route path="*" element={<InvalidURL />} />
	</Routes>
);

export default PageDebug;
