/*
 * P&F DRE web application
 *
 * Error page, for various types of error.
 */

import React from 'react';

import { Page, PageHeading, PageContent } from '../../components/Page';
import { ErrorMessage } from '../../components/ErrorMessage';

interface TProps {
	heading?: string;
	title: string;
	children: React.ReactNode;
}

const PageError: React.FC<TProps> = (props) => (
	<Page classSuffix="Error">
		<PageHeading title={props.heading ?? 'Error'} />
		<PageContent>
			<ErrorMessage title={props.title}>
				{props.children}
			</ErrorMessage>
		</PageContent>
	</Page>
);

export default PageError;
