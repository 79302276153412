/*
 * P&F DRE web application
 *
 * Page for showing pf-dre-collector specific details.
 */

import * as React from 'react';

import Button from '../../../components/Button';
import { Page, PageHeading, PageContent } from '../../../components/Page';
import CollectorDeviceRefresh from '../../../components/CollectorDeviceRefresh';
import CollectorActivity from '../../../components/CollectorActivity';

const PageSystemCollector: React.FC = () => {
	return (
		<Page classSuffix="SystemCollector">
			<PageHeading
				title="Collector"
			/>
			<PageContent>
				<h4>Refresh device list</h4>
				<p>
					After device information has been altered in the database (such as
					adding or removing a device, or changing its IP address) the
					Collector will pick up the new information every 15 minutes or so.
				</p>
				<p>
					To avoid waiting for the next automatic refresh, you can have the
					Collector update this information immediately by clicking the button
					below.
				</p>
				<CollectorDeviceRefresh />

				<h4>Collector activity</h4>
				<CollectorActivity />
			</PageContent>
		</Page>
	);
}

export default PageSystemCollector;
