/*
 * P&F DRE web application
 *
 * Development page for designing various UI elements.
 */

import React from 'react';

import Button from '../../../components/Button';
import { ErrorMessage } from '../../../components/ErrorMessage';
import Loading from '../../../components/Loading';
import { Page, PageHeading, PageContent } from '../../../components/Page';
import './styles.css';

type TTimer = ReturnType<typeof setTimeout>;

const PageDebugUI: React.FC = () => {

	const [lastTimer, setLastTimer] = React.useState<TTimer | undefined>();
	const [active, setActive] = React.useState<boolean>(false);
	const fnActivate = React.useCallback(() => {
		setActive(true);
		if (lastTimer) {
			clearTimeout(lastTimer);
		}
		setLastTimer(
			setTimeout(() => {
				setActive(false);
			}, 1000)
		);
	}, [
		lastTimer,
		setActive,
		setLastTimer,
	]);

	return (
		<Page classSuffix="DebugUI">
			<PageHeading title="Debug / UI">
				<div className="controls">
					<div>
						<Loading />
					</div>
					<Button type="refresh" isActive={active} onClick={fnActivate}>Click to activate</Button>
					<Button type="refresh" isActive={true}>Active</Button>
					<Button type="refresh">Refresh</Button>
					<Button type="search">Search</Button>
					<Button type="terminate">Terminate</Button>
				</div>
			</PageHeading>
			<PageContent>
				<div className="controls">
					<div>
						<Loading />
					</div>
					<Button type="refresh" isActive={active} onClick={fnActivate}>Click to activate</Button>
					<Button type="refresh" isActive={true}>Active</Button>
					<Button type="refresh">Refresh</Button>
					<Button type="search">Search</Button>
					<Button type="terminate">Terminate</Button>
					<ErrorMessage title="Long pre inside ErrorMessage">
						<p>
							This should wrap:
							1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890
							./node_modules/@pmmmwh/react-refresh-webpack-plugin/lib/runtime/RefreshUtils.js/createDebounceUpdate/enqueueUpdate/refreshTimeout&lt;@http://localhost:3000/static/js/bundle.js:4813:17
						</p>
						<pre>
							This should scroll:
							1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890
							./node_modules/@pmmmwh/react-refresh-webpack-plugin/lib/runtime/RefreshUtils.js/createDebounceUpdate/enqueueUpdate/refreshTimeout&lt;@http://localhost:3000/static/js/bundle.js:4813:17
						</pre>
						<pre>
							This should be too short to see any scroll bars.
						</pre>
					</ErrorMessage>
				</div>
			</PageContent>
		</Page>
	);
}

export default PageDebugUI;
