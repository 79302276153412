/*
 * P&F DRE web application
 *
 * Form that manages data entry fields and uses a callback when the user wants
 * to save.
 */

import * as React from 'react';

import './styles.css';

export { default as FormInputTextExpanding } from './FormInputTextExpanding';
export { default as FormPanel } from './FormPanel';

export type TFormData = Record<string, string | null>;
export type TFnSetModified = (isModified: boolean) => void;

// Actual save function that stores the data perhaps by making an API call.
// Supplied by the user of this component.
export type TFnSave = (values: TFormData) => Promise<void>;

// Function to initiate the save, set the animations, etc.  Used by
// save/cancel buttons.  Implemented by this component, but passed around so
// that other components can trigger a save operation.
export type TFnFormSave = (abandon?: boolean) => void;

// Function to pass the save function around, so that the parent component can
// get a function it can use to trigger a save, e.g. for the onClick handers
// in the save and cancel buttons.
export type TFnSetFormSave = (cbSave: TFnFormSave) => void;

export interface TFormContext {
	activeValues: any;
	setValue: (fieldName: string, fieldValue: string) => void;
};

// Create a React Context to pass the current form values down to the child
// components, regardless of how deep they are in the hierarchy.  This allows
// them to receive information about the form, even if they are contained
// within an arbitrary number of sub-components, like div and span.
export const FormContext = React.createContext<TFormContext>({
	activeValues: {},
	setValue: () => { },
});

interface Props {
	values: TFormData;
	children?: React.ReactNode;
	fnSave: TFnSave;
	fnSetModified: TFnSetModified;
	fnSetFormSave: TFnSetFormSave;
};

const Form: React.FC<Props> = ({ values, children, fnSave, fnSetModified, fnSetFormSave }) => {
	const [activeValues, setActiveValues] = React.useState<TFormData>({});

	const performSave = React.useCallback<TFnFormSave>(async (abandon?: boolean) => {
		if (abandon !== true) {
			// Save changes.
			try {
				console.log('Saving form:', activeValues);
				await fnSave(activeValues);
			} catch (e: unknown) {
				// If we got an error we assume the caller already alerted the user
				// somehow, so all we need to do is abort the save so the user can
				// try again after fixing the problem.
			}
		} else {
			// Abandon changes and restore original values to the fields.
			setActiveValues({});
		}
	}, [
		activeValues,
		fnSave,
		setActiveValues,
	]);

	// Pass the save function to the parent any time it changes.
	React.useEffect(() => {
		// Can't pass the function or it gets called.
		fnSetFormSave(() => performSave);
	}, [
		fnSetFormSave,
		performSave,
	]);

	// If the original values change (e.g. after a save operation), remove any
	// active entries that are the same.
	React.useEffect(() => {
		// Normally we'd run the for loop here and call setActiveValues(), however
		// that requires us to access `activeValues`, which means we'd have to add
		// it as a `useEffect()` dependency.  That however results in an infinite
		// loop, as the code will be triggered each time `activeValues` changes,
		// but running the code also changes `activeValues`.
		//
		// By using a setState callback function, we get passed the old state,
		// equivalent to `activeValues`, without having to list it as a dependency.
		setActiveValues(oldValues => {
			let newActiveValues: Record<string, string> = {};
			for (const [fieldName, fieldValue] of Object.entries(oldValues)) {
				if (values[fieldName] !== fieldValue) {
					// Keep changed value.
					if (fieldValue === null) continue;
					newActiveValues[fieldName] = fieldValue;
				}
			}
			return newActiveValues;
		});
	}, [
		setActiveValues,
		values,
	]);


	// Figure out whether the data has changed from the original value, and
	// either way, notify the parent via the fnSetModified callback.
	// This could be placed inside setValue() below, except we want it to
	// trigger when the save function removes the active values as well.
	React.useEffect(() => {
		fnSetModified(Object.keys(activeValues).length > 0);
	}, [
		activeValues,
		fnSetModified,
	]);

	// Change one of the form values.  Used by the child input fields to make
	// them controlled components.
	const setValue = React.useCallback((fieldName: string, newValue: string) => {
		if (values[fieldName] === newValue) {
			// Value set to same as original
			setActiveValues((prev) => {
				let result = { ...prev };
				delete result[fieldName];
				return result;
			});
		} else {
			setActiveValues((prev) => ({
				...prev,
				[fieldName]: newValue,
			}));
		}
	}, [
		setActiveValues,
		values,
	]);

	// Calculate the value to pass down into the Context.  See the FormContext
	// definition above for details.
	const formContextValue = React.useMemo<TFormContext>(() => ({
		activeValues: {
			...values,
			...activeValues,
		},
		setValue: setValue,
	}), [
		activeValues,
		setValue,
		values,
	]);

	return (
		<FormContext.Provider value={formContextValue}>
			{children}
		</FormContext.Provider>
	);
};

export default Form;
