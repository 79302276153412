/*
 * P&F DRE web application
 *
 * Display a single database connection passed in as a prop, and call the
 * termination API if requested.
 */

import * as React from 'react';
import {
	useDatabaseTerminateConnectionMutation,
} from '../../generated/graphql';
import DatabaseConnectionListEntry, {
	TConnection,
	TFnTerminate,
} from './DatabaseConnectionListEntry';

export type { TConnection } from './DatabaseConnectionListEntry';

interface Props {
	connection: TConnection;
};

const DatabaseConnectionListEntryContainer: React.FC<Props> = ({
	connection,
}) => {
	const [terminateConnection, { error, loading }] = useDatabaseTerminateConnectionMutation();

	const fnTerminate: TFnTerminate = React.useCallback(() => {
		// Call the GraphQL mutate function.
		if (connection && connection.idConnection) {
			terminateConnection({
				variables: {
					idConnection: connection.idConnection,
				}
			});
		} else {
			console.error('Invalid connection object:', connection);
		}
	}, [
		connection,
		terminateConnection,
	]);

	return (
		<DatabaseConnectionListEntry
			connection={connection}
			isLoading={loading}
			error={error}
			fnTerminate={fnTerminate}
		/>
	);
};

export default DatabaseConnectionListEntryContainer;
