/*
 * P&F DRE web application
 *
 * Main routing for electric vehicle (EV) pages.
 */

import React from 'react';
import {
	Routes,
	Route,
} from 'react-router-dom';

import PageEVSummary from './summary';

const PageEV: React.FC = () => (
	<Routes>
		<Route path="" element={<PageEVSummary />} />
		<Route path="*" element={<p>Invalid EV URL.</p>} />
	</Routes>
);

export default PageEV;
