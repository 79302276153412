/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import Button from '../Button';
import { ErrorMessage } from '../ErrorMessage';
import JobTypeEntry from './JobTypeEntry';
import JobParametersCalculateAssetEnergies from './JobParametersCalculateAssetEnergies';
import JobParametersSumMetricsDaily from './JobParametersSumMetricsDaily';
import JobParametersUpdateIntervalMetrics from './JobParametersUpdateIntervalMetrics';
import JobParametersRecalculateVirtualMeter from './JobParametersRecalculateVirtualMeter';
import { TJob } from '../JobListEntry';
import { sortJobs } from '../JobList/JobList';

import './styles.css';

interface Props {
	fnAddJob: Function;
	jobList: TJob[];
}

type TJobParameters = typeof JobParametersCalculateAssetEnergies;

const JobNew: React.FC<Props> = ({ fnAddJob, jobList }) => {
	const [parameters, setParameters] = React.useState<any>({});
	const [jobType, setJobType] = React.useState<string | null>(null);
	const [idJobWaitFor, setIdJobWaitFor] = React.useState<number | null>(null);

	const sortedList = React.useMemo(() => (
		// We have to sort a copy of the list - sorting in place throws a TypeError
		// because the prop is apparently immutable.
		sortJobs([...jobList])
	), [
		jobList,
	]);

	// Figure out whether the parameters are all present and valid.
	const invalidReason = React.useMemo<string | null>(() => {
		switch (jobType) {
			case 'calculateAssetEnergies':
				return null;

			case 'sumMetricsDaily':
				if (!parameters.dayStart) {
					return 'Missing/invalid start day.';
				}
				if (!parameters.dayEnd) {
					return 'Missing/invalid end day.';
				}
				if (Date.parse(parameters.dayStart) >= Date.parse(parameters.dayEnd)) {
					return 'End day must be after the start day.';
				}
				return null;

			case 'updateIntervalMetrics':
				if (!parameters.startTime) {
					return 'Missing/invalid start date.';
				}
				return null;

			case 'recalculateVirtualMeter':
				if (!parameters.startTime) {
					return 'Missing/invalid start date.';
				}
				return null;

			case null:
				return 'Please select a job.';

			default:
				return 'Unknown job type.';
		}
	}, [
		jobType,
		parameters,
	]);

	const JobParameters: TJobParameters = {
		'calculateAssetEnergies': JobParametersCalculateAssetEnergies,
		'sumMetricsDaily': JobParametersSumMetricsDaily,
		'updateIntervalMetrics': JobParametersUpdateIntervalMetrics,
		'recalculateVirtualMeter': JobParametersRecalculateVirtualMeter,
	}[jobType ?? 'null'] ?? (() => null);

	return (
		<div className="JobNew">
			<div>
				<p>
					Select a job to run:
				</p>
				<ul className="availableJobs">
					<JobTypeEntry
						jobType="calculateAssetEnergies"
						title="Calculate asset energies"
						selectedType={jobType}
						onClick={setJobType}
					>
						<p>
							Sum the energy imported and exported for monitored assets (e.g.
							Tesla battery and Warwick Solar Farm), and upload the result onto
							S3 for the dashboards to display.
						</p>
					</JobTypeEntry>
					<JobTypeEntry
						jobType="sumMetricsDaily"
						title="Sum daily metrics"
						selectedType={jobType}
						onClick={setJobType}
					>
						<p>
							Combine all metrics for one day into a single reading, and write
							it to the <samp>device_metrics_daily</samp> database table.
							Metrics are combined based on their type (e.g. current is
							averaged, energy is summed, min/max is used for min/max metrics,
							etc.)
						</p>
						<p>
							This job is run automatically just after midnight for the previous
							day's data, and only needs to be run manually after historical
							data in the <samp>device_metrics_json</samp> table has been
							changed.
						</p>
					</JobTypeEntry>
					<JobTypeEntry
						jobType="updateIntervalMetrics"
						title="Update interval metrics"
						selectedType={jobType}
						onClick={setJobType}
					>
						<p>
							Recalculate the interval metrics for the given devices and time
							range.  This finds the difference between each reading for Ea_Imp,
							Ea_Exp, and other energy metrics, and saves the delta to metrics
							Ea_Imp_Interval, Ea_Exp_Interval, etc., taking into account meter
							wraparound.
						</p>
						<p>
							After this job has been run, if any data has been updated, a
							<em>Sum daily metrics</em> job should be run to also update the
							daily totals with the new interval metrics.
						</p>
					</JobTypeEntry>
					<JobTypeEntry
						jobType="recalculateVirtualMeter"
						title="Recalculate virtual meters"
						selectedType={jobType}
						onClick={setJobType}
					>
						<p>
							Delete and recalculate all metrics for the given virtual meters
							(or any virtual meters using the given feeder meters).
						</p>
						<p>
							After this job has been run, if any data has been updated, a
							<em>Sum daily metrics</em> job should be run to also update the
							daily totals with the new metrics.
						</p>
					</JobTypeEntry>
				</ul>
			</div>
			{jobType && (
				<div>
					<p>
						Enter parameters for the selected job:
					</p>
					<JobParameters
						parameters={parameters}
						setParameters={setParameters}
					/>
					<table className="fields">
						<tr>
							<td className="name">
								<label htmlFor="waitFor">Wait for:</label>
							</td>
							<td className="value">
								<select
									value={idJobWaitFor ?? 0}
									onChange={(ev) => setIdJobWaitFor(parseInt(ev.target.value, 10))}
								>
									<option
										value={0}
										onClick={() => setIdJobWaitFor(null)}
									>
										None, run immediately
									</option>
									{sortedList.map(job => (
										<option
											value={job.idJob}
										>
											[{job.idJob}] {job.jobType}
										</option>
									))}
								</select>
							</td>
						</tr>
					</table>
					{(invalidReason && (
						<ErrorMessage title="Incomplete parameters">
							{invalidReason}
						</ErrorMessage>
					)) || (
							<Button
								type="add"
								onClick={() => fnAddJob({
									jobType: jobType,
									idJobWaitFor: idJobWaitFor,
									parameters: JSON.stringify(parameters),
								})}
							/>
						)}
				</div>
			)}
		</div>
	);
};

export default JobNew;
