/*
 * P&F DRE web application
 *
 * Standard page styling, main content.
 */

import * as React from 'react';

import './styles.css';

interface TProps {
	children: React.ReactNode;
}

const PageContent: React.FC<TProps> = (props) => (
	<div className="PageContent">
		{props.children}
	</div>
);


export default PageContent;
