/*
 * P&F DRE web application
 *
 * Extract detail from an Apollo GraphQL API error, and display in a HTML
 * bullet list.
 */

import React from 'react';
import { ApolloError, ServerError } from '@apollo/client';
import ShowMore from './ShowMore';

import './styles.css';

interface Props {
	// Exception to examine for error messages.
	exception: any;

	// Show the "show more..." link.  Defaults to `true`.  Pass `false` to never
	// show the link, e.g. for error messages within tooltip popups.
	showMore?: boolean;
};

function stackToString(s: string | string[]) {
	if (Array.isArray(s)) {
		return s.join('\n');
	}
	return s;
}

const PrettyException: React.FC<Props> = ({ exception, showMore }) => {

	React.useEffect(() => {
		if ((exception !== null) && (exception !== undefined)) {
			// Log the error to the browser console as well.
			console.error('Exception:', JSON.stringify(exception, null, 2));
		}
	}, [
		exception,
	]);

	const errors = React.useMemo(() => {

		const apolloError: ApolloError | null = (exception instanceof ApolloError) ? exception : null;

		let errors = [
			...(apolloError?.graphQLErrors ?? []),
			...(apolloError?.clientErrors ?? []),
		];

		// Sometimes network errors have a structure, e.g. bad GraphQL request.
		if (apolloError?.networkError?.name === 'ServerError') {
			const serverError = apolloError.networkError as ServerError;
			errors = [
				...errors,
				...serverError?.result?.errors,
			];
		}

		// But sometimes they don't, e.g. connection refused.
		if (apolloError?.networkError?.message) {
			errors.push(apolloError.networkError);
		}

		if (errors.length === 0) {
			if (exception?.message) {
				// Couldn't figure out what it was, fall back to base class.
				errors.push(exception);
			} else {
				const dummyError = new Error('No error message given.');
				dummyError.stack = undefined;
				errors.push(dummyError);
			}
		}

		return errors;
	}, [
		exception,
	]);

	return (
		<ul>
			{errors.map((err, index) => (
				<li key={index}>
					{err.message}
					{err.stack && (showMore !== false) && (
						<ShowMore>
							<pre>
								{stackToString(err.stack)}
							</pre>
						</ShowMore>
					)}
				</li>
			))}
		</ul>
	);
}

export default PrettyException;
