import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { HashRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
	uri: process.env.REACT_APP_API_ENDPOINT,
	cache: new InMemoryCache({
		typePolicies: {
			GTGateway: {
				fields: {
					meters: {
						// Merge the incoming meters from the GraphQL query in with the
						// existing meters in the Apollo cache.  We do this by joining
						// the arrays but ensuring any with the same ID only appear in
						// the final combined list once (as we don't want the same meter
						// appearing multiple times on the same gateway).
						merge(existing = [], incoming: any[], { readField }) {
							let combined: Record<string, any> = {};
							for (const i of existing) {
								// Note this will break if ever the ID isn't included in the
								// list of retrieved fields in the GQL query.
								combined[readField<string>('id', i) || 0] = i;
							}
							for (const i of incoming) {
								combined[readField<string>('id', i) || 0] = i;
							}
							return Object.values(combined);
						},
					},
				},
			},

		},
	}),
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<HashRouter>
				<App />
			</HashRouter>
		</ApolloProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
