/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	parameters: any;
	setParameters: React.SetStateAction<any>;
}

const JobParametersCalculateAssetEnergies: React.FC<Props> = ({ parameters, setParameters }) => {
	React.useEffect(() => {
		setParameters({ valid: true });
	});

	return (
		<div className="parameters">
			<p>
				<i>This job does not require any parameters.</i>
			</p>
		</div>
	);
};

export default JobParametersCalculateAssetEnergies;
