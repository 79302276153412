/*
 * P&F DRE web application
 *
 * Display a button to trigger a refresh of the Collector's device list.
 */

import * as React from 'react';

import singletonLive, {
	TMetrics,
	TSubscriptionID,
} from '../../api/live';
import SmoothMessages from '../../api/smooth-messages';

import CollectorActivity from './CollectorActivity';

const device = '78.08';

export interface TReadings {
	id: string;
	code: string;
	metrics: TMetrics;
}

let idNext = 1;

const CollectorActivityContainer: React.FC = () => {
	const [recentReadings, setRecentReadings] = React.useState<TReadings[]>([]);

	const fnNewReading = React.useCallback((code: string, metrics: TMetrics) => {
		setRecentReadings((prev) => ([
			{
				id: String(idNext++),
				code,
				metrics,
			},
			...(prev.slice(0, 9)),
		]));
	}, [
		setRecentReadings,
	]);

	React.useEffect(() => {
		let sub: TSubscriptionID | null = null;
		let unloaded = false;
		(async () => {
			const sm = new SmoothMessages(fnNewReading);
			sub = singletonLive.subscribeDevices([device], sm.getCallback());
			if (unloaded) {
				// Component got unmounted while the `await` was in progress, so the
				// cleanup function has already been called, and could do nothing as
				// we didn't have the subscription ID yet.  So we have to unsub now.
				singletonLive.unsubscribeDevices(sub);
			}
		})();
		return function cleanup() {
			if (sub) {
				singletonLive.unsubscribeDevices(sub);
			}
			unloaded = true;
		};
	}, []);

	return <CollectorActivity items={recentReadings} />;
};

export default CollectorActivityContainer;
