/*
 * P&F DRE web application
 *
 * Render the fields for adding a new job to the DB.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	jobType: string;
	title: string;
	children: React.ReactNode;
	selectedType: string | null;
	onClick: (jobType: string) => void;
}

const JobTypeEntry: React.FC<Props> = ({
	jobType,
	title,
	children,
	selectedType,
	onClick,
}) => {
	return (
		<li
			id={jobType}
			className={(selectedType === jobType) ? 'selected' : ''}
			onClick={() => onClick(jobType)}
		>
			<h4>
				<span>{title}</span>
				<span>{(selectedType === jobType) && ' ✅'}</span>
			</h4>
			{children}
		</li>
	);
};

export default JobTypeEntry;
