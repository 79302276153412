/*
 * P&F DRE web application
 *
 * Display a single database connection passed in as a prop, and call the
 * termination API if requested.
 */

import * as React from 'react';
import {
	useDatabaseTerminateConnectionMutation,
} from '../../generated/graphql';
import EVChargerListEntry, {
	TConnectorStatus,
	TFnCallback,
} from './EVChargerListEntry';

export type { TConnectorStatus } from './EVChargerListEntry';

interface Props {
	connectorStatus: TConnectorStatus;
};

const EVChargerListEntryContainer: React.FC<Props> = ({
	connectorStatus,
}) => {
	const [terminateConnection, { error, loading: isPendingStartCharge }] = useDatabaseTerminateConnectionMutation();
	const isPendingStopCharge = false; // temp
	const isPendingStartDischarge = false; // temp

	const fnStartCharge: TFnCallback = React.useCallback(() => {
		// Call the GraphQL mutate function.
		/*
			 if (connection && connection.idConnection) {
			 terminateConnection({
			 variables: {
			 idConnection: connection.idConnection,
			 }
			 });
			 } else {
			 console.error('Invalid connection object:', connection);
			 }
		 */
	}, [
		connectorStatus,
		//terminateConnection,
	]);

	const fnStopCharge: TFnCallback = React.useCallback(() => {
		console.log('TODO');
	}, [
	]);

	const fnStartDischarge: TFnCallback = React.useCallback(() => {
		console.log('TODO');
	}, [
	]);

	return (
		<EVChargerListEntry
			connectorStatus={connectorStatus}
			isPendingStartCharge={isPendingStartCharge}
			isPendingStopCharge={isPendingStopCharge}
			isPendingStartDischarge={isPendingStartDischarge}
			error={error}
			fnStartCharge={fnStartCharge}
			fnStopCharge={fnStopCharge}
			fnStartDischarge={fnStartDischarge}
		/>
	);
};

export default EVChargerListEntryContainer;
