/*
 * P&F DRE web application
 *
 * Grid arrangement of <Panel /> components.
 */

import * as React from 'react';

import './styles.css';

interface Props {
	children: React.ReactNode;
};

const PanelGrid: React.FC<Props> = ({ children }) => (
	<div className="PanelGrid">
		{children}
	</div>
);

export default PanelGrid;
