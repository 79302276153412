/*
 * P&F DRE web application
 *
 * Render the supplied list of active database connections.
 */

import * as React from 'react';

import DatabaseConnectionListEntry, { TConnection } from '../DatabaseConnectionListEntry';
import './styles.css';

interface Props {
	connectionList: TConnection[];
}

const DatabaseConnectionList: React.FC<Props> = ({ connectionList }) => {
	const hasConnections = connectionList.length > 0;

	const sortedList = React.useMemo(() => (
		// We have to sort a copy of the list - sorting in place throws a TypeError
		// because the prop is apparently immutable.
		[...connectionList].sort(
			// Put the earliest connections at the top of the list.
			(a, b) => (a.connectedAt < b.connectedAt) ? 0 : 1
		)
	), [
		connectionList,
	]);

	return (
		<div className="DatabaseConnectionList">
			{hasConnections ? (
				<ul>
					{sortedList.map(c => (
						<DatabaseConnectionListEntry
							key={c.idConnection}
							connection={c}
						/>
					))}
				</ul>
			) : (
				<p>
					No connections.
				</p>
			)}
		</div>
	);
};

export default DatabaseConnectionList;
