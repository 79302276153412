/*
 * P&F DRE web application
 *
 * Page for showing active jobs/tasks.
 */

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import JobList from '../../../components/JobList';
import { Page, PageHeading, PageContent } from '../../../components/Page';

type TRefreshCallback = () => void;
type TPollCallback = () => void;

const PageDatabaseJobs: React.FC = () => {
	const navigate = useNavigate();

	const [fnRefreshCallback, setFnRefreshCallback] = React.useState<TRefreshCallback>();
	const [refreshing, setRefreshing] = React.useState<boolean>(false);

	const [fnPollCallback, setFnPollCallback] = React.useState<TPollCallback>();
	const [polling, setPolling] = React.useState<boolean>(false);

	// We can't call setFnRefreshCallback(fn) because when we pass a function to
	// setState(), React calls it immediately with the previous state.  So in
	// order to put a callback function into the state, we have to pass setState
	// a function, which returns the function that should go into the state.  We
	// do this inside useCallback() here.
	const setRefreshCallback = React.useCallback((cb: TRefreshCallback) => {
		// Pass in a function that can be called immediately, and returns `cb`.
		setFnRefreshCallback(_prevState => cb);
	}, [
		setFnRefreshCallback,
	]);

	const setPollCallback = React.useCallback((cb: TPollCallback) => {
		// Pass in a function that can be called immediately, and returns `cb`.
		setFnPollCallback(_prevState => cb);
	}, [
		setFnPollCallback,
	]);

	return (
		<Page classSuffix="DatabaseJobs">
			<PageHeading
				title="System Jobs"
				actions={
					<>
						<Button
							type="refresh"
							isActive={refreshing}
							onClick={() => (fnRefreshCallback && fnRefreshCallback())}
							title="Update the job list with the latest progress of each job."
						/>
						<Button
							type="add"
							onClick={() => navigate('new/')}
							title="Create a new job."
						/>
						<Button
							type="trigger"
							isActive={polling}
							onClick={() => (fnPollCallback && fnPollCallback())}
							title="Start the next pending job immediately, to avoid waiting for the next 15-minute poll to start it."
						/>
					</>
				}
			/>
			<PageContent>
				<p>
					These jobs are scheduled automatically by the system.  If a job ever
					fails, after the problem has been fixed it can be re-run from here.
				</p>
				<JobList
					setRefreshCallback={setRefreshCallback}
					setRefreshing={setRefreshing}
					setPollCallback={setPollCallback}
					setPolling={setPolling}
				/>
				<p style={{ color: 'var(--uq-secondary-darkgrey)' }}>
					These jobs are long running, execute on the ECS cluster, and many of
					them must finish to avoid data loss.
					Quick jobs that run at regular intervals (and that can recover if
					some executions are missed) typically run on AWS Lambda (triggered by
					CloudWatch Events) and are not listed here.
				</p>
			</PageContent>
		</Page>
	);
}

export default PageDatabaseJobs;
